import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography, InputAdornment } from '@material-ui/core';

import { EMPTY_MAP } from 'app/app.constants';
import { required, requiredBetween } from 'utils/validation.util';
import { formatQuantity } from 'utils/format.util';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import {
  SimulationDirection,
  SimulationFormFields as FormFields,
} from 'features/projects/tasks/task/simulation/simulation.constants';
import { requestUpdateSimulation } from 'features/projects/tasks/task/simulation/simulation.actions';
import {
  getSimulationFromState,
  createSimulationCompressionDepthByDirectionSelector,
} from 'features/projects/tasks/task/simulation/simulation.selectors';

const useStyles = makeStyles((theme) =>
  createStyles({
    gridStyle: {
      border: `1px solid ${theme.palette.grey[600]}`,
      padding: theme.spacing(2),
    },
    labelStyle: {
      fontSize: '16px',
      marginBottom: '5px',
      marginTop: '10px',
    },
    simulationSelectorStyle: {
      border: `1px solid ${theme.palette.grey[600]}`,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    iconStyle: {
      verticalAlign: 'top',
      marginRight: 10,
    },
  }),
);

const TaskSimulationOptionsContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { projectId, taskId, simulationId } = useParams();
  const simulation =
    useSelector((state) => getSimulationFromState(state, simulationId)) ??
    EMPTY_MAP;
  let compressionDepth = useSelector(
    createSimulationCompressionDepthByDirectionSelector(simulationId),
  );
  const compressionDepthRih = useSelector(
    createSimulationCompressionDepthByDirectionSelector(
      simulationId,
      SimulationDirection.RUN_IN_HOLE,
    ),
  );
  const compressionDepthPooh = useSelector(
    createSimulationCompressionDepthByDirectionSelector(
      simulationId,
      SimulationDirection.PULL_OUT_OF_HOLE,
    ),
  );

  // compression depth - take smallest depth out of two data sets as instructed by Torben
  if (!compressionDepth) {
    if (compressionDepthRih && compressionDepthPooh) {
      compressionDepth = { runInHole: undefined, pullOutOfHole: undefined };
      const rihValue =
        compressionDepthRih?.runInHole > compressionDepthPooh?.runInHole
          ? compressionDepthPooh.runInHole
          : compressionDepthRih.runInHole;

      if (rihValue) {
        compressionDepth.runInHole = rihValue;
      }

      const poohValue =
        compressionDepthRih?.pullOutOfHole > compressionDepthPooh?.pullOutOfHole
          ? compressionDepthPooh.pullOutOfHole
          : compressionDepthRih.pullOutOfHole;

      if (poohValue) {
        compressionDepth.pullOutOfHole = poohValue;
      }
    }
  }

  const isPlanned = simulation.get('isPlanned');

  return (
    <Formik
      enableReinitialize
      onSubmit={(simulation) =>
        dispatch(
          requestUpdateSimulation(projectId, taskId, simulationId, simulation),
        )
      }
      initialValues={simulation.toJS()}
    >
      <AutoSaveFormik>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Field
              type="number"
              validate={required}
              disabled={isPlanned}
              label="Stuffing Box Friction"
              component={QuantityTextFieldFormik}
              name={FormFields.STUFFING_BOX_FRICTION}
            />
            <Typography className={classes.labelStyle}>
              Depth of interest
            </Typography>
            <Grid className={classes.gridStyle}>
              <Field
                required
                type="number"
                label="Calculate From"
                validate={required}
                disabled={isPlanned}
                component={QuantityTextFieldFormik}
                name={FormFields.CALCULATE_FROM_DEPTH}
              />
              <Field
                required
                type="number"
                label="Calculate To"
                validate={required}
                disabled={isPlanned}
                component={QuantityTextFieldFormik}
                name={FormFields.CALCULATE_TO_DEPTH}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              validate={requiredBetween(0, 1)}
              disabled={isPlanned}
              label="Eccentricity"
              component={TextFieldFormik}
              name={FormFields.ECCENTRICITY}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    between 0 and 1
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.labelStyle}>
              Weakpoint Limits
            </Typography>
            <Grid className={classes.gridStyle}>
              <Field
                type="number"
                label="Weakpoint"
                component={QuantityTextFieldFormik}
                name={FormFields.WEAKPOINT}
                disabled={isPlanned}
              />
              <Field
                type="number"
                label="Safety Factor"
                component={TextFieldFormik}
                disabled={isPlanned}
                name={FormFields.WEAKPOINT_SAFETY_FACTOR}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.labelStyle}>
              Working Limits
            </Typography>
            <Grid className={classes.gridStyle}>
              <Field
                type="number"
                label="Safe Operating Load"
                component={TextFieldFormik}
                name={FormFields.SAFE_OPERATING_LOAD_SAFETY_FACTOR}
                disabled={isPlanned}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      % of breaking strength
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {compressionDepth && (
            <Grid item xs={12}>
              <Typography className={classes.labelStyle}>
                Compression Depth
              </Typography>
              {compressionDepth.runInHole && (
                <Grid
                  container
                  className={classes.gridStyle}
                  justifyContent="flex-start"
                >
                  <Typography className={classes.labelStyle}>
                    <InfoIcon className={classes.iconStyle} />
                    Cable compression is detected while Run In Hole at{' '}
                    {formatQuantity(compressionDepth.runInHole)}. You cannot get
                    below this depth with the current input parameters.
                  </Typography>
                </Grid>
              )}
              {compressionDepth.pullOutOfHole && (
                <Grid
                  container
                  className={classes.gridStyle}
                  style={{ marginTop: 10 }}
                  justifyContent="flex-start"
                >
                  <Typography className={classes.labelStyle}>
                    <InfoIcon className={classes.iconStyle} />
                    Cable compression is detected while Pull Out Of Hole at{' '}
                    {formatQuantity(compressionDepth.pullOutOfHole)}.
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </AutoSaveFormik>
    </Formik>
  );
};

export default TaskSimulationOptionsContainer;

import { Map, List, Set } from 'immutable';

export const EMPTY_STRING = '';
export const EMPTY_MAP = Map();
export const EMPTY_LIST = List();
export const EMPTY_SET = Set();

export const MAX_UPLOAD_FILE_SIZE = 100_000_000; // 100MB

export const EMPTY_QUANTITY = {
  unit: EMPTY_STRING,
  value: null,
  hasValue: false,
};

export const EMPTY_QUANTITY_WITH_UNIT = (unit) => ({
  unit: unit,
  value: null,
  hasValue: false,
});

export const ENVIRONMENTS = {
  LOCAL: 'Local',
  DEV: 'ALTUSDEV',
  TST: 'ALTUSTST',
  PRD: 'ALTUSPRD',
  DEM: 'ALTUSDEM',
  TRAINING: 'ALTUSTRAINING',
};

export const ENVIRONMENTS_NAMES = {
  [ENVIRONMENTS.LOCAL]: 'Local',
  [ENVIRONMENTS.DEV]: 'Dev',
  [ENVIRONMENTS.TST]: 'Test',
  [ENVIRONMENTS.DEM]: 'Demo',
  [ENVIRONMENTS.TRAINING]: 'Training',
};

export const APP_ACTIONS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_THEME: 'SET_THEME',
  APP_LOAD: 'APP_LOAD',
  ASYNC_END: 'ASYNC_END',
  MODAL_NEXT: 'MODAL_NEXT',
  REDIRECT_TO: 'REDIRECT_TO',
  ASYNC_START: 'ASYNC_START',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  EVENTS_LOADED: 'EVENTS_LOADED',
  MODAL_PREVIOUS: 'MODAL_PREVIOUS',
  CLEAR_REDIRECT: 'CLEAR_REDIRECT',
  CREATE_PROJECT: 'CREATE_PROJECT',
  PROJECT_LOADED: 'PROJECT_LOADED',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  INITIATE_FETCH_CHANGE_LOG: 'INITIATE_FETCH_CHANGE_LOG',
  INITIATE_FETCH_PRIVACY_POLICY: 'INITIATE_FETCH_PRIVACY_POLICY',
  STORE_CHANGE_LOG: 'STORE_CHANGE_LOG',
  NEW_APP_VERSION_NOTIFICATION: 'NEW_APP_VERSION_NOTIFICATION',
  SHOW_CHANGE_LOG_SEPARATE_TAB: 'SHOW_CHANGE_LOG_SEPARATE_TAB',
  GET_MAIN_VENDOR_USERS: 'GET_MAIN_VENDOR_USERS',
  GET_SYSTEM_ROLES: 'GET_SYSTEM_ROLES',
  GET_ALL_SERVICES: 'GET_ALL_SERVICES',
  TOGGLE_USER_MENU: 'TOGGLE_USER_MENU',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  SET_HEADER_VALUES: 'SET_HEADER_VALUES',
  EVENTS_PAGE_LOADED: 'EVENTS_PAGE_LOADED',
  GET_ALL_DEPARTMENTS: 'GET_ALL_DEPARTMENTS',
  PROJECT_SET_SUBPAGE: 'PROJECT_SET_SUBPAGE',
  EVENTS_MARK_AS_SEEN: 'EVENTS_MARK_AS_SEEN',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  SET_APPLICATION_FAILURE: 'SET_APPLICATION_FAILURE',
  PROJECT_MARK_AS_SEEN: 'PROJECT_MARK_AS_SEEN',
  EVENTS_PAGE_UNLOADED: 'EVENTS_PAGE_UNLOADED',
  DELETE_PROJECT_MEMBER: 'DELETE_PROJECT_MEMBER',
  UPDATE_PROJECT_MEMBER: 'UPDATE_PROJECT_MEMBER',
  SELECT_PROJECT_MEMBER: 'SELECT_PROJECT_MEMBER',
  DESELECT_PROJECT_MEMBER: 'DESELECT_PROJECT_MEMBER',
  PROJECT_MEMBER_PAGE_LOADED: 'PROJECT_MEMBER_PAGE_LOADED',
  PROJECT_TEAM_PAGE_UNLOADED: 'PROJECT_TEAM_PAGE_UNLOADED',
  CONFIRMATION_DIALOG_CONFIRM: 'CONFIRMATION_DIALOG_CONFIRM',
  CONFIRMATION_DIALOG_PENDING: 'CONFIRMATION_DIALOG_PENDING',
  PROJECT_DETAILS_PAGE_LOADED: 'PROJECT_DETAILS_PAGE_LOADED',
  TOGGLE_PROJECT_MEMBER_DIALOG: 'TOGGLE_PROJECT_MEMBER_DIALOG',
  PROJECT_DETAILS_PAGE_UNLOADED: 'PROJECT_DETAILS_PAGE_UNLOADED',
  CLEAR_SELECTED_PROJECT_MEMBERS: 'CLEAR_SELECTED_PROJECT_MEMBERS',
  UPDATE_USER_MEASUREMENT_PREFERENCES: 'UPDATE_USER_MEASUREMENT_PREFERENCES',
  GET_ALL_FILE_CATEGORIES: 'GET_ALL_FILE_CATEGORIES',
  GET_USER_PREFERENCES: 'GET_USER_PREFERENCES',
  GET_APPLICATION_FEATURES: 'GET_APPLICATION_FEATURES',
  GET_ALL_ORGANIZATIONS: 'GET_ALL_ORGANIZATIONS',
  SET_CURRENT_CLIENT_ORGANIZATION: 'SET_CURRENT_CLIENT_ORGANIZATION',
  SAVE_REDIRECT_PATH: 'SAVE_REDIRECT_PATH',
};

export const UPLOAD_DOCUMENT_FORM = {
  ID: 'uploadDocument',
  META: 'meta',
  FILE: 'file',
  FILES: 'files',
  META_NAME: 'meta.name',
  META_CATEGORY: 'meta.category',
  META_EXTENSION: 'meta.extension',
};

export const MEASUREMENT_PREFERENCES_FORM = {
  ID: 'measurementPreferences',
};

export const USER_PREFERENCES_FORM = {
  ID: 'userPreferences',
  RECEIVE_DAILY_EMAIL: 'general.receiveDailyDigestEmail',
  THEME: 'general.theme',
  SYSTEM: 'units.system',
};

export const MODAL = {
  UPLOAD_FILE: 'UPLOAD_FILE_MODAL_ID',
  CONFIRMATION_DIALOG: 'CONFIRMATION_DIALOG_MODAL_ID',
  APPLICATION_FAILURE: 'APPLICATION_FAILURE_MODAL_ID',
  EDIT_USER_PREFERENCES: 'preferences',
};

export const KEYS = {
  TAB: 'Tab',
  ENTER: 'Enter',
  SHIFT: 'Shift',
  ESCAPE: 'Escape',
  BACKSPACE: 'Backspace',
  SPACE: ' ',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
};

export const IN_DEV = process.env.NODE_ENV === 'development' ? true : false;

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
};

export const HTTP_REASON = {
  SITE_ACCESS_DENIED: 8,
};

// PROJECTS
export const PROJECT_STATUS = {
  PLAN: 1,
  EXECUTE: 2,
  REPORT: 3,
};

export const ProjectWellboreDetailStatus = {
  DRAFT: 0,
  REQUIRES_APPROVAL: 1,
  APPROVED: 2,
};

// WORK ITEM STATUS (TASK / ACTIVITIES)
export const WORK_ITEM_STATUS = {
  NOT_SET: 0,
  PLANNED: 0x0001,
  STARTED: 0x0010,
  PAUSED: 0x0011,
  COMPLETED: 0x0020,
  SKIPPED: 0x0021,
  FAILED: 0x0022,
};

// MISC
export const Format = {
  date: 'YYYY/MM/DD',
  date_dayfirst: 'DD/MM/YYYY',
  time: 'YYYY/MM/DD HH:mm',
  time_long: 'YYYY/MM/DD HH:mm:ss',
  datetime_local: 'YYYY-MM-DDTHH:mm',
  input: 'YYYY-MM-DD',
  duration: 'HH:mm:ss',
  duration_display: 'HH[h] mm[m]',
  duration_short: 'HH:mm',
  human: {
    date: 'dddd[,] Do [of] MMMM',
  },
};

export const DIRECTION = {
  STATIONARY: 'STATIONARY',
  UP: 'UP',
  DOWN: 'DOWN',
};

export const SLIDE_DIRECTIONS = {
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
};

export const NOTIFICATION_VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
};

export const METERS_PER_FEET = 1 / (1 / 0.0254 / 12);

export const TIME_UNITS = {
  YEAR: 'years',
  MONTH: 'months',
  WEEK: 'weeks',
  DAY: 'days',
  HOUR: 'hours',
  MINUTE: 'minutes',
  SECOND: 'seconds',
  MILLISECONDS: 'milliseconds',
};

export const UNITS = {
  METER: 'm',
  FEET: 'ft',
  POUND: 'lb',
  POUND_WEIGHT: 'lbs',
  KILO_POUND: 'klbs',
  POUND_FORCE: 'lbf',
  NEWTON: 'N',
  KILO_POUND_FORCE: 'kipf',
  KILO_GRAM_FORCE: 'kgf',
  KILO: 'kg',
  BARG: 'bar',
  PSIG: 'psi',
  M_MIN: 'm/min',
  M_S: 'm/s',
  FT_MIN: 'ft/min',
  DEG: 'deg',
  CELCIUS: 'C',
  FAHRENHEIT: 'F',
  INCHES: 'in',
  MILLIMETER: 'mm',
  M_3_MIN: 'm3/min',
  GAL_MIN: 'gal/min',
  FT_S: 'ft/s',
  M_3: 'm³',
  GALLON_US: 'gal (U.S.)',
  M_3_DAY: 'm³/d',
  FT_3_DAY: 'ft³/d',
  BARRELS_DAY: 'bpd',
  KILOGRAM_CUBIC_METER: 'kg/m³',
  STANDARD_CUBIC_METER_PER_DAY: 'Sm³/d',
  THOUSAND_STANDARD_CUBIC_FEET_PER_DAY: 'kft³/d',
  OIL_BARRELS_PER_DAY: 'bbl/d',
  KILOGRAM_KILOMETER: 'kg/km',
  LBS_1000FEET: 'lbs/1000 ft',
  POUND_PER_US_GALLON: 'ppg (U.S.)',
  METER_PER_KILOMETER_PER_5000NEWTON: 'm/km/5KN',
  FEET_PER_1000FEET_PER_1000LBS: 'ft/Kft/Klbf',
};

export const CONVERT_ALIASES = {
  [UNITS.POUND]: ['lbs', 'Lbs'],
  [UNITS.POUND_FORCE]: ['lbf', 'Lbf'],
  [UNITS.BARG]: ['barg'],
  [UNITS.CELCIUS]: ['°C'],
  [UNITS.FAHRENHEIT]: ['°F'],
  [UNITS.GAL_MIN]: ['Gal/min'],
  [UNITS.PSIG]: ['psig'],
  [UNITS.M_3_MIN]: ['m³/min'],
};

export const CUSTOM_UNIT_SYSTEM = 'Custom';
export const USE_PROJECT_DEFAULT_UNIT_SYSTEM = 'Project Default';

export const CONVERT_MEASURES = {
  LENGTH: 'length',
  PRESSURE: 'pressure',
  SPEED: 'speed',
  TEMPERATURE: 'temperature',
  MASS: 'mass',
  VOLUME_FLOW_RATE: 'volumeFlowRate',
  VOLUME: 'volume',
  DEPTH: 'depth',
  FORCE: 'force',
  BIG_FORCE: 'bigForce',
  TOOL_DIAMETER: 'toolDiameter',
  TUBULAR_DIAMETER: 'tubularDiameter',
  GAS_FLOW_RATE: 'gasFlowRate',
  LIQUID_RATE: 'liquidRate',
  GAS_DENSITY: 'gasDensity',
  LIQUID_DENSITY: 'liquidDensity',
  WEIGHT_IN_AIR: 'weightInAir',
  STRETCH_COEFFICIENT: 'stretchCoefficient',
};

export const CONVERT_MEASURES_NAMES = {
  [CONVERT_MEASURES.LENGTH]: 'Length',
  [CONVERT_MEASURES.PRESSURE]: 'Pressure',
  [CONVERT_MEASURES.SPEED]: 'Speed',
  [CONVERT_MEASURES.TEMPERATURE]: 'Temperature',
  [CONVERT_MEASURES.MASS]: 'Mass',
  [CONVERT_MEASURES.VOLUME]: 'Volume',
  [CONVERT_MEASURES.VOLUME_FLOW_RATE]: 'Volume flow rate',
  [CONVERT_MEASURES.DEPTH]: 'Depth',
  [CONVERT_MEASURES.FORCE]: 'Force',
  [CONVERT_MEASURES.BIG_FORCE]: 'Big Force',
  [CONVERT_MEASURES.TOOL_DIAMETER]: 'Tool Diameter',
  [CONVERT_MEASURES.TUBULAR_DIAMETER]: 'Tubular Diameter',
  [CONVERT_MEASURES.GAS_FLOW_RATE]: 'Gas Flow Rate',
  [CONVERT_MEASURES.LIQUID_RATE]: 'Liquid Rate',
  [CONVERT_MEASURES.GAS_DENSITY]: 'Gas Density',
  [CONVERT_MEASURES.LIQUID_DENSITY]: 'Liquid Density',
  [CONVERT_MEASURES.WEIGHT_IN_AIR]: 'Weight in Air',
  [CONVERT_MEASURES.STRETCH_COEFFICIENT]: 'Stretch Coefficient',
};

export const CONVERT_SYSTEMS = {
  METRIC: 'metric',
  IMPERIAL: 'imperial',
};

export const UNITS_BY_CATEGORY = {
  [CONVERT_MEASURES.LENGTH]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.METER,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.FEET,
  },
  [CONVERT_MEASURES.PRESSURE]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.BARG,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.PSIG,
  },
  [CONVERT_MEASURES.SPEED]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.M_S,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.FT_S,
  },
  [CONVERT_MEASURES.TEMPERATURE]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.CELCIUS,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.FAHRENHEIT,
  },
  [CONVERT_MEASURES.MASS]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.KILO,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.POUND,
  },
  [CONVERT_MEASURES.VOLUME]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.M_3,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.GALLON_US,
  },
  [CONVERT_MEASURES.VOLUME_FLOW_RATE]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.M_3_MIN,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.GAL_MIN,
  },
  [CONVERT_MEASURES.DEPTH]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.METER,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.FEET,
  },
  [CONVERT_MEASURES.FORCE]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.NEWTON,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.POUND_FORCE,
  },
  [CONVERT_MEASURES.BIG_FORCE]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.KILO_GRAM_FORCE,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.KILO_POUND_FORCE,
  },
  [CONVERT_MEASURES.TOOL_DIAMETER]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.MILLIMETER,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.INCHES,
  },
  [CONVERT_MEASURES.TUBULAR_DIAMETER]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.MILLIMETER,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.INCHES,
  },
  [CONVERT_MEASURES.GAS_FLOW_RATE]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.STANDARD_CUBIC_METER_PER_DAY,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.THOUSAND_STANDARD_CUBIC_FEET_PER_DAY,
  },
  [CONVERT_MEASURES.LIQUID_RATE]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.M_3_DAY,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.OIL_BARRELS_PER_DAY,
  },
  [CONVERT_MEASURES.GAS_DENSITY]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.KILOGRAM_CUBIC_METER,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.POUND_PER_US_GALLON,
  },
  [CONVERT_MEASURES.LIQUID_DENSITY]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.KILOGRAM_CUBIC_METER,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.POUND_PER_US_GALLON,
  },
  [CONVERT_MEASURES.WEIGHT_IN_AIR]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.KILOGRAM_KILOMETER,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.LBS_1000FEET,
  },
  [CONVERT_MEASURES.STRETCH_COEFFICIENT]: {
    [CONVERT_SYSTEMS.METRIC]: UNITS.METER_PER_KILOMETER_PER_5000NEWTON,
    [CONVERT_SYSTEMS.IMPERIAL]: UNITS.FEET_PER_1000FEET_PER_1000LBS,
  },
};

export const LIMIT = {
  GRAPH_TIME_SPAN: 7200,
  UNIT: {
    [UNITS.CELCIUS]: 300,
    bar: 600,
    [UNITS.BARG]: 600,
    bara: 600,
    //TODO: Find max tension dynamically?
    [UNITS.KILO]: 1800,
    [UNITS.POUND_FORCE]: 4000,
    //TODO: Find max speed dynamically?
    [UNITS.M_S]: 4,
    [UNITS.M_MIN]: 250, // corresponds to 15 km/h
  },
};

export const CUSTOM_UNITS = {
  [UNITS.M_MIN]: {
    measure: CONVERT_MEASURES.SPEED,
    system: CONVERT_SYSTEMS.METRIC,
  },
  [UNITS.FT_MIN]: {
    measure: CONVERT_MEASURES.SPEED,
    system: CONVERT_SYSTEMS.IMPERIAL,
  },
};

export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const THEME_COOKIE_ID = 'digitalIntervention_theme';
export const BEARER_TOKEN_COOKIE_ID = 'digitalIntervention_bearer';

export const MSAL_PREFIX = 'msal.';

export const NORWAY_MAP_CENTER_COORDS = [60.472, 8.4689];
export const EUROPE_MAP_CENTER_COORDS = [59.526, 25.2551];

export const PUBLIC_ASSETS_URL = process.env.REACT_APP_PUBLIC_ASSETS_URL;

export const APP_NAME = process.env.REACT_APP_APP_TITLE;
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;
export const APP_VERSION_STORAGE_KEY = 'CURRENT_APP_VERSION';
// export const APP_SHORTNAME = 'DWI';
export const APP_SHORTNAME = 'CHOREOGRAF Collaborate';
export const LIVE_OPERATIONS_APP_NAME = 'Collaborate LiveOps';

export const OrganizationType = {
  MAIN_VENDOR: 0,
  THIRD_PARTY: 1,
  CUSTOMER: 2,
  BAKER_HUGHES: 3,
};

export const ProjectPermission = {
  FULL_ACCESS: 100000,
  EDIT_PROJECT_DETAILS: 10000,
  INITIATE_DELETE_PROJECT: 10001,
  EDIT_WELL_DETAILS: 10100,
  APPROVE_WELL_DETAILS: 10101,
  EXPORT_WELL_DETAILS: 10102,
  MANAGE_TEAM_MEMBERS_ACCESS: 10200,
  DELETE_TEAM_MEMBERS: 10201,
  ADD_TEAM_MEMBERS: 10202,
  UPLOAD_DOCUMENTS: 10300,
  DOWNLOAD_DOCUMENTS: 10301,
  DELETE_ALL_DOCUMENTS: 10302,
  CREATE_TASKS: 10400,
  DELETE_TASKS: 10401,
  EDIT_TASKS: 10402,
  EDIT_TASK_DETAILS: 10500,
  EDIT_TASK_WELL_DETAILS: 10600,
  EDIT_TOOLSTRING: 10700,
  CREATE_TOOLSTRING: 10701,
  DELETE_TOOLSTRING: 10702,
  CREATE_SIMULATION: 10800,
  EDIT_SIMULATION: 10801,
  DELETE_SIMULATION: 10802,
  EDIT_ACTIVITIES: 10900,
  CREATE_ACTIVITY: 10901,
  DELETE_ACTIVITY: 10902,
  READ_ACTIVITY_COMMENTS: 10903,
  CREATE_LESSONS: 11100,
  EDIT_LESSONS: 11101,
  DELETE_LESSONS: 11102,
  READ_ACTIVITY_COMMENTS_REPORT: 11200,
  EXECUTE: 12000,
  READ_ACTIVITY_COMMENTS_EXECUTE: 12100,
  CREATE_INCIDENTS: 12200,
  EDIT_INCIDENTS: 12201,
  DELETE_INCIDENTS: 12202,
};

export const SystemPermission = {
  CREATE_PROJECTS: 1000,
  DELETE_PROJECTS: 1001,
  MANAGE_SYSTEM_ROLES: 1100,
  MANAGE_PROJECT_ROLES: 1200,
  MANAGE_USER_ACCESS: 1300,
  MANAGE_EQUIPMENT: 1400,
  MANAGE_SERVICES: 1500,
  MANAGE_WELLS: 1600,
  MANAGE_SIMULATIONS: 1700,
  DATA_EXCHANGE: 1800,
  EQUIPMENT_TAB_VIEW: 1802,
  ITEM_TAB_EDIT: 1804,
  THIRD_PARTY_TAB_EDIT: 1805,
  ITEM_VERIFICATION: 1810,
  WELL_ACCESS_ONLY: 1900,
  DASHBOARD_CONFIGURATION_ACCESS: 2000,
  INTEGRITY_RULES_MANAGEMENT: 2100,
};

export const ProjectRoles = {
  OWNER: 200,
  PLANNER: 201,
  PLANNING_AND_SUPPORT: 202,
  CREW: 203,
  VIEWER: 204,
  CLIENT_OWNER: 205,
  THIRD_PARTY : 206,
  VIEWER_AND_COMMUNICATIONS: 207,
};

export const FormikFormStatus = {
  SUCCESS: 0,
  ERROR: 1,
};

export const UnitSystem = {
  METRIC: 0,
  IMPERIAL: 1,
};

export const CheetahJobStatus = {
  OTHER: 0,
  STREAMING: 1,
  STOPPED: 2,
};

export const SmLocationStatus = {
  UNKNOWN: 0,
  PING: 1,
  NO_PING: 2,
};

export const PagedQueryParams = {
  PAGE: 'pageParams.page',
  PAGE_SIZE: 'pageParams.pageSize',
};

export const FacilityType = {
  FIXED: 0,
  MOBILE: 1,
};

export const M3SearchType = {
  ITEMS: 1,
  SERIALNUMBERS: 2,
};

export const RiskConsequenceEnum = {
  Major: { id: 1, name: '1 - Major' },
  Serious: { id: 2, name: '2 - Serious' },
  Moderate: { id: 3, name: '3 - Moderate' },
  Minor: { id: 4, name: '4 - Minor' },
  Negligible: { id: 5, name: '5 - Negligible' },
};

export const RiskProbabilityEnum = {
  VeryLikely: { id: 1, name: 'A - Very Likely' },
  Likely: { id: 2, name: 'B - Likely' },
  Possible: { id: 3, name: 'C - Possible' },
  Unlikely: { id: 4, name: 'D - Unlikely' },
  VeryUnlikely: { id: 5, name: 'E - Very Unlikely' },
};

export const RiskStateEnum = {
  Open: { id: 1, name: 'Open' },
  Mitigated: { id: 2, name: 'Mitigated' },
  Accepted: { id: 3, name: 'Accepted' },
};

export const RiskMatrix = [
  [
    'N/A',
    'A-Very Likely',
    'B-Likely',
    'C-Possible',
    'D-Unlikely',
    'E-Very Unlikely',
  ],
  ['1-Major', 'Red', 'Red', 'Red', 'Red', 'Yellow'],
  ['2-Serious', 'Red', 'Red', 'Red', 'Yellow', 'Yellow'],
  ['3-Moderate', 'Red', 'Yellow', 'Yellow', 'Yellow', 'Green'],
  ['4-Minor', 'Yellow', 'Yellow', 'Green', 'Green', 'Green'],
  ['5-Negligible', 'Yellow', 'Green', 'Green', 'Green', 'Green'],
];

export const FrictionReducerTypeEnum = {
  StarLube: 1,
  StarGilde: 2,
  RamexRX72: 3,
  BaraLubeW511: 4,
  KLAROTMLUBENS: 5,
  Other: 6,
};

export const RiskLevels = {
  Red: 3,
  Yellow: 2,
  Green: 1,
  'N/A': 0,
};

export const RiskPriorityMatrix = [
  [null, null, null, null, null, null],
  [null, 1, 2, 3, 4, 9],
  [null, 5, 6, 7, 10, 11],
  [null, 8, 12, 13, 14, 18],
  [null, 15, 16, 19, 20, 21],
  [null, 17, 22, 23, 24, 25],
];

export const WellControlDrillResult = List([
  { id: '1', name: 'Above Average' },
  { id: '2', name: 'Average' },
  { id: '3', name: 'Below Average' },
]);

export const EMPTY_QUANTITY_LENGTH = {
  unit: UNITS.METER,
  value: null,
  hasValue: false,
};

export const ERROR_CODE_WINDOW_POP_UP_DISABLED = 'popup_window_error';

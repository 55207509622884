import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import logo from 'assets/collaborate_logo.svg';

const Logo = ({ classes, rootUrl }) => {
  return (
    <Grid
      component={rootUrl ? Link : undefined}
      to={rootUrl ? rootUrl : undefined}
    >
      <img
        src={logo}
        alt="Choreograf Collaborate Logo"
        className={classes.logo}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  logo: {
    display: 'flex',
    height: theme.spacing(3),
    paddingLeft: theme.spacing(1),
  },
});

export default withStyles(styles)(Logo);

import React, { useEffect, useRef } from 'react';
import { Plot } from '@int/geotoolkit/plot/Plot';
import { WellLogWidget } from '@int/geotoolkit/welllog/widgets/WellLogWidget';
import { TrackType } from '@int/geotoolkit/welllog/TrackType';
import { LogCurve } from '@int/geotoolkit/welllog/LogCurve';
import { LogData } from '@int/geotoolkit/welllog/data/LogData';
import { CompositeSchematicsWidget } from '@int/geotoolkit/schematics/widgets/CompositeSchematicsWidget';
import { ProportionalWidthStrategy } from '@int/geotoolkit/deviation/ProportionalWidthStrategy';
import { PiecewiseTransformer } from '@int/geotoolkit/deviation/PiecewiseTransformer';
import { Group } from '@int/geotoolkit/scene/Group';
import { CssLayout } from '@int/geotoolkit/layout/CssLayout';
import schematicData from './wellLogBasic/helpers/schematic.json';
import { useSimpleDataSource } from './wellLogBasic/helpers/SimpleDataSource.js';

const SCALE_FACTOR = 1.2;

const TestingContainerWellLogAndCompositeSchematic = () => {
  const plotRef = useRef(null);
  const dataSource = useSimpleDataSource();
  const wellLogWidgetRef = useRef(null);
  const schematicWidgetRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!dataSource) {
      console.log('Data source is not loaded yet.');
      return;
    }

    // Initialize WellLogWidget
    const wellLogWidget = new WellLogWidget({
      indextype: 'number',
      indexunit: 'ft',
      header: { visible: false },
      footer: { visible: false },
    });

    wellLogWidget.addTrack(TrackType.IndexTrack).setWidth(50);

    const logTrack1 = wellLogWidget
      .addTrack(TrackType.LinearTrack)
      .setWidth(200);

    dataSource.curveNames.forEach((curveName, index) => {
      const color = index % 2 === 0 ? 'green' : 'red';
      const curve = new LogCurve(new LogData(curveName))
        .setLineStyle(color)
        .setName(curveName);
      logTrack1.addChild(curve);
    });

    for (let i = 0; i < 2; i++) {
      const duplicateTrack = wellLogWidget
        .addTrack(TrackType.LinearTrack)
        .setWidth(200);
      dataSource.curveNames.forEach((curveName, index) => {
        const color = index % 2 === 0 ? 'green' : 'red';
        const duplicateCurve = new LogCurve(new LogData(curveName))
          .setLineStyle(color)
          .setName(curveName);
        duplicateTrack.addChild(duplicateCurve);
      });
    }

    dataSource.connect(wellLogWidget);
    wellLogWidget.setDepthScale(10);
    wellLogWidget.fitToHeight();

    // Initialize CompositeSchematicsWidget
    const DEFAULT_STRATEGY_OPTIONS = {
      maxwidth: 250,
      minwidth: 30,
      widthscale: 20,
    };
    const TRANSFORMER = new PiecewiseTransformer({
      trackwidthstrategy: new ProportionalWidthStrategy(
        DEFAULT_STRATEGY_OPTIONS,
      ),
    });

    const schematicWidget = new CompositeSchematicsWidget({
      gap: {
        top: { visible: false, resizable: false, size: '0' },
        left: { visible: true, resizable: false, size: '30px' },
        right: { visible: true, resizable: false, size: '250px' },
        bottom: { visible: false, resizable: false, size: '0' },
      },
      deviation: {
        transformer: TRANSFORMER,
        trackwidth: 350,
        offset: 0,
      },
      data: { elements: schematicData },
    });

    // Configure Plot
    const plot = new Plot({
      canvaselement: canvasRef.current,
      root: new Group().setLayout(new CssLayout()).addChild([
        wellLogWidget.setLayoutStyle({
          left: 0,
          top: 0,
          width: '50%',
          height: '100%',
        }),
        schematicWidget.setLayoutStyle({
          left: '50%',
          top: 0,
          width: '50%',
          height: '100%',
        }),
      ]),
    });

    plotRef.current = plot;
    wellLogWidgetRef.current = wellLogWidget;
    schematicWidgetRef.current = schematicWidget;

    console.log(
      'Widget and Plot initialized with WellLogWidget and CompositeSchematicsWidget.',
    );

    return () => {
      if (plotRef.current) {
        plotRef.current.dispose();
      }
      if (dataSource) {
        dataSource.dispose();
      }
    };
  }, [dataSource]);

  const synchronizeZoom = (scaleFactor) => {
    if (wellLogWidgetRef.current && schematicWidgetRef.current) {
      // Scale the WellLogWidget
      wellLogWidgetRef.current.scale(scaleFactor);

      // Scale the CompositeSchematicsWidget using scaleModel
      schematicWidgetRef.current.scaleModel(scaleFactor, scaleFactor, 0, 0);
    }
  };

  const zoomIn = () => synchronizeZoom(SCALE_FACTOR);
  const zoomOut = () => synchronizeZoom(1 / SCALE_FACTOR);
  const zoomToFit = () => {
    if (wellLogWidgetRef.current && schematicWidgetRef.current) {
      // Fit WellLogWidget to height
      wellLogWidgetRef.current.fitToHeight();

      // Fit CompositeSchematicsWidget to bounds
      schematicWidgetRef.current.fitToBounds();
    }
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <canvas ref={canvasRef} width="1600" height="800" />
      <div>
        <button onClick={zoomIn}>Zoom In</button>
        <button onClick={zoomOut}>Zoom Out</button>
        <button onClick={zoomToFit}>Zoom to Fit</button>
      </div>
    </div>
  );
};

export default TestingContainerWellLogAndCompositeSchematic;

import React, { useEffect, useRef } from 'react';
import { Plot } from '@int/geotoolkit/plot/Plot';
import { WellLogWidget } from '@int/geotoolkit/welllog/widgets/WellLogWidget';
import { TrackType } from '@int/geotoolkit/welllog/TrackType';
import { LogCurve } from '@int/geotoolkit/welllog/LogCurve';
import { LogData } from '@int/geotoolkit/welllog/data/LogData';
import { WellBoreNode } from '@int/geotoolkit/schematics/scene/WellBoreNode';
import { WellBoreData } from '@int/geotoolkit/schematics/data/WellBoreData';
import { Rect } from '@int/geotoolkit/util/Rect';
import { CssLayout } from '@int/geotoolkit/layout/CssLayout';
import { ToolTipRegistry } from '@int/geotoolkit/controls/tooltip/ToolTipRegistry';
import { Group } from '@int/geotoolkit/scene/Group';
import schematicData from './helpers/schematic.json';
import { useSimpleDataSource } from './helpers/SimpleDataSource.js';
import { ComponentNode } from '@int/geotoolkit/schematics/scene/ComponentNode';
// import { NodeTooltip } from '@int/geotoolkit/controls/tooltip/NodeTooltip';
// import { SchematicsComponentToolTip } from './helpers/SchematicsTooltip';
// import { Selector } from '@int/geotoolkit/selection/Selector';
import { LogCurveTooltip } from '@int/geotoolkit/welllog/tooltip/LogCurveTooltip';
import { ComponentNodeTooltip } from '@int/geotoolkit/schematics/tooltip/ComponentNodeTooltip';
import { ComponentNodeFactoryRegistry } from '@int/geotoolkit/schematics/factory/ComponentNodeFactoryRegistry';
// import { SVGComponentsLoader } from '@int/geotoolkit/schematics/factory/SVGComponentsLoader';

import { Text } from '@int/geotoolkit/scene/shapes/Text';
import { Arrow } from '@int/geotoolkit/scene/shapes/Arrow';
import { Point } from '@int/geotoolkit/util/Point';
import { KnownColors } from '@int/geotoolkit/util/ColorUtil';

const SCALE_FACTOR = 1.2;

const BigData = () => {
  const plotRef = useRef(null);
  const dataSource = useSimpleDataSource();
  const wellLogWidgetRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!dataSource) {
      console.log('Data source is not loaded yet.');
      return;
    }

    const registry = new ComponentNodeFactoryRegistry(true);

    const wellLogWidget = new WellLogWidget({
      indextype: 'number',
      indexunit: 'ft',
      header: { visible: false },
      footer: { visible: false },
    });

    wellLogWidget.addTrack(TrackType.IndexTrack).setWidth(50);

    // Add curve data with tooltips to the well log widget
    // Original log track with curves
    const logTrack1 = wellLogWidget
      .addTrack(TrackType.LinearTrack)
      .setWidth(200);
    dataSource.curveNames.forEach((curveName, index) => {
      const color = index % 2 === 0 ? 'green' : 'red';
      const curve = new LogCurve(new LogData(curveName))
        .setLineStyle(color)
        .setName(curveName);
      logTrack1.addChild(curve);
    });

    for (let i = 0; i < 2; i++) {
      const duplicateTrack = wellLogWidget
        .addTrack(TrackType.LinearTrack)
        .setWidth(200);
      dataSource.curveNames.forEach((curveName, index) => {
        const color = index % 2 === 0 ? 'green' : 'red';
        const duplicateCurve = new LogCurve(new LogData(curveName))
          .setLineStyle(color)
          .setName(curveName);
        duplicateTrack.addChild(duplicateCurve);
      });
    }

    dataSource.connect(wellLogWidget);
    wellLogWidget.setDepthScale(10);
    wellLogWidget.fitToHeight();

    // Set up WellBoreData and WellBoreNode for the schematic widget
    const wellBoreData = new WellBoreData(schematicData);

    const wellBoreNode = new WellBoreNode({
      data: wellBoreData,
      registry: registry,
    });

    const minDepth = Math.min(
      ...schematicData.map((item) => item.data.geometry.depth.from),
    );
    const maxDepth = Math.max(
      ...schematicData.map((item) => item.data.geometry.depth.to),
    );

    wellBoreNode.setBounds(new Rect(0, minDepth, 0.65, maxDepth - minDepth));

    // Calculate the maximum outer diameter
    const maxOuterDiameter = Math.max(
      ...schematicData.map(
        (component) => component.data.geometry.diameter.outer,
      ),
    );

    // Create labelsGroup and add labels and arrows based on schematicData
    const labelsGroup = new Group();

    // Estimate label height based on font size
    const fontSize = 12; // Font size in pixels
    const labelHeight = fontSize * 0.9; // Approximate label height with line spacing
    const minLabelSpacing = labelHeight; // Minimum spacing between labels

    // Collect label data
    const labelData = schematicData.map((component) => {
      // eslint-disable-next-line
      const { name, data } = component;
      const description = data.description;
      const geometry = data.geometry;
      const depthFrom = geometry.depth.from;
      const depthTo = geometry.depth.to;
      const midDepth = (depthFrom + depthTo) / 2;

      // Create the label
      const label = new Text({
        text: description,
        align: 'left',
        valign: 'middle',
        font: `${fontSize}px Arial`,
      });

      return {
        label,
        midDepth,
        component,
        depthFrom,
        depthTo,
      };
    });

    // Sort labels by midDepth
    labelData.sort((a, b) => a.midDepth - b.midDepth);

    let lastLabelY = null;

    // eslint-disable-next-line
    labelData.forEach((labelInfo, index) => {
      let { label, midDepth } = labelInfo;

      // Adjust label position to prevent overlap
      if (lastLabelY !== null && midDepth - lastLabelY < minLabelSpacing) {
        midDepth = lastLabelY + minLabelSpacing;
        labelInfo.midDepth = midDepth; // Update midDepth in labelInfo
      }

      lastLabelY = midDepth;

      // Ensure label stays within depth bounds
      if (midDepth - labelHeight / 2 < minDepth) {
        midDepth = minDepth + labelHeight / 2;
        labelInfo.midDepth = midDepth;
      }
      //  if (midDepth + labelHeight / 2 > maxDepth) {
      //    midDepth = maxDepth - labelHeight / 2;
      //    labelInfo.midDepth = midDepth;
      //  }

      // Set the adjusted position to the label
      const xLabel = 0.8;
      label.setAnchorX(xLabel);
      label.setAnchorY(midDepth);

      // Add the label to labelsGroup
      labelsGroup.addChild(label);

      // Compute xComponent based on the component's outer diameter
      const componentOuterDiameter =
        labelInfo.component.data.geometry.diameter.outer;
      const componentHalfWidth =
        (componentOuterDiameter / maxOuterDiameter) * 0.325;
      const xCenter = 0.325;
      const xComponent = xCenter + componentHalfWidth;

      // Create an arrow from the label to the component
      const arrow = new Arrow({
        from: new Point(xLabel - 0.15, midDepth),
        to: new Point(
          xComponent,
          (labelInfo.depthFrom + labelInfo.depthTo) / 2,
        ),
        symbolstart: null,
        symbolend: null,
        linestyle: {
          color: KnownColors.Black,
          width: 1,
        },
      });

      labelsGroup.addChild(arrow);
    });

    const schematicTrack = wellLogWidget
      .addTrack(TrackType.LinearTrack)
      .setWidth(350);

    schematicTrack.addChild(wellBoreNode);
    schematicTrack.addChild(labelsGroup);

    wellLogWidget.addTrack(TrackType.IndexTrack).setWidth(50);

    // Initialize ToolTipRegistry
    const toolTipRegistry = new ToolTipRegistry();

    // Register tooltip for LogCurve
    toolTipRegistry.register(
      LogCurve.getClassName(),
      // eslint-disable-next-line no-template-curly-in-string
      new LogCurveTooltip('${name} - Depth: ${depth}'),
    );

    // Register tooltip for ComponentNode (schematic components)
    toolTipRegistry.register(
      ComponentNode.getClassName(),
      new ComponentNodeTooltip(),
    );

    // Attach the tooltip tool to wellLogWidget and set the registry
    const tooltipTool = wellLogWidget.getToolByName('tooltip');
    tooltipTool.setRegistry(toolTipRegistry);
    tooltipTool.setEnabled(true);

    // Configure the Plot with the WellLogWidget
    const plot = new Plot({
      canvaselement: canvasRef.current,
      root: new Group().setLayout(new CssLayout()).addChild([
        wellLogWidget.setLayoutStyle({
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }),
      ]),
    });

    wellLogWidget.fitToHeight();
    plotRef.current = plot;
    wellLogWidgetRef.current = wellLogWidget;

    console.log(
      'Widget and Plot initialized with WellLogWidget and WellBoreNode.',
    );

    return () => {
      if (plotRef.current) {
        plotRef.current.dispose();
      }
      if (dataSource) {
        dataSource.dispose();
      }
    };
  }, [dataSource]);

  const zoomIn = () => {
    if (wellLogWidgetRef.current) {
      wellLogWidgetRef.current.scale(SCALE_FACTOR);
    }
  };

  const zoomOut = () => {
    if (wellLogWidgetRef.current) {
      wellLogWidgetRef.current.scale(1 / SCALE_FACTOR);
    }
  };

  const zoomToFit = () => {
    if (wellLogWidgetRef.current) {
      wellLogWidgetRef.current.fitToHeight();
    }
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <canvas ref={canvasRef} width="1600" height="800" />
      <div>
        <button onClick={zoomIn}>Zoom In</button>
        <button onClick={zoomOut}>Zoom Out</button>
        <button onClick={zoomToFit}>Zoom to Fit</button>
      </div>
    </div>
  );
};

export default BigData;

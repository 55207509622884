import service from 'services/app.service';
import { HTTP_STATUS } from 'app/app.constants';
import wellboreService from 'services/wellbore.service';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import wellboreDetailService from 'services/wellboreDetail.service';
import { getWellboreByIdFromState } from 'features/wells/wellbore.selectors';
import { WELLS_ACTIONS as WELLBORE_ACTIONS } from 'features/wells/wells.constants';

export const getWellbore = (fieldId, wellboreId) => (dispatch) => {
  const payload = wellboreService
    .getWellbore(fieldId, wellboreId)
    .then((wellbore) => {
      const { wellboreDetailId, wellboreDetail } = wellbore;

      dispatch(receiveWellbore(wellbore));

      if (wellboreDetailId) {
        dispatch(receiveWellboreDetail(wellboreDetail));
      }

      return wellbore;
    });

  dispatch({
    payload,
    type: WELLBORE_ACTIONS.GET_WELLBORE,
  });

  return payload;
};

export const wellboreContainerOnLoad = (fieldId, wellId) => (dispatch) => {
  const payload = dispatch(getWellbore(fieldId, wellId)).then((wellbore) => {
    const { wellboreDetailId, wellboreDetail } = wellbore;

    if (wellboreDetailId) {
      dispatch(receiveWellboreDetail(wellboreDetail));
    } else {
      dispatch(createWellboreDetail(fieldId, wellId));
    }
  });

  dispatch({
    payload,
    type: WELLBORE_ACTIONS.WELLBORE_CONTAINER_LOADED,
  });

  return payload;
};

export const updateFilter = (filter) => (dispatch) => {
  const payload = wellboreService
    .searchWellbores(filter)
    .then((wellboresPage) => {
      return dispatch(receiveWellboresPage(wellboresPage));
    });

  dispatch({
    filter,
    payload,
    type: WELLBORE_ACTIONS.UPDATE_WELL_FILTER,
  });

  return payload;
};

export const updateWellAperioFilter = (filter) => (dispatch) => {
  const payload = wellboreService
    .searchAperioWellbores(filter)
    .then((wellboresPage) => {
      return dispatch(receiveWellboresPage(wellboresPage));
    });

  dispatch({
    filter,
    payload,
    type: WELLBORE_ACTIONS.UPDATE_WELL_FILTER,
  });

  return payload;
};

export const receiveFields = (fields) => ({
  payload: fields,
  type: WELLBORE_ACTIONS.RECEIVE_FIELDS,
});

export const getFields = (filters) => (dispatch) => {
  const payload = service
    .getFields(filters)
    .then((fields) => dispatch(receiveFields(fields)));

  dispatch({
    payload,
    type: WELLBORE_ACTIONS.GET_FIELDS,
  });

  return payload;
};

export const onLoad = (organizationId) => (dispatch) => {
  const filter = {
    organizationId,
  };

  const payload = Promise.all([
    wellboreService.getWellboreStatuses(),
    dispatch(getFields(filter)),
  ]);

  dispatch({
    type: WELLBORE_ACTIONS.WELLS_PAGE_LOADED,
    payload,
  });

  return payload;
};

export const getWellboreTrajectory = (fieldId, wellboreId) => (dispatch) => {
  const payload = wellboreService
    .getWellboreTrajectory(fieldId, wellboreId)
    .catch((error) => {
      if (error.status === HTTP_STATUS.NOT_FOUND) {
        return [];
      }
      return Promise.reject(error);
    })
    .then((trajectory) =>
      dispatch(receiveWellboreTrajectory(wellboreId, trajectory)),
    );

  dispatch({
    payload,
    type: WELLBORE_ACTIONS.REQUEST_WELLBORE_TRAJECTORY,
  });

  return payload;
};

export const receiveWellboreTrajectory = (wellboreId, trajectory) => ({
  wellboreId,
  payload: trajectory,
  type: WELLBORE_ACTIONS.RECEIVE_WELLBORE_TRAJECTORY,
});

export const receiveWellboreDetail = (wellboreDetail) => ({
  payload: wellboreDetail,
  type: WELLBORE_ACTIONS.RECEIVE_WELLBORE_DETAIL,
});

export const receiveWellbore = (wellbore) => ({
  payload: wellbore,
  type: WELLBORE_ACTIONS.RECEIVE_WELLBORE,
});

export const updateWellbore = (wellboreId, wellbore) => ({
  wellboreId,
  payload: wellbore,
  type: WELLBORE_ACTIONS.UPDATE_WELLBORE,
});

export const receiveWellboresPage = (wellbores) => ({
  payload: wellbores,
  type: WELLBORE_ACTIONS.RECEIVE_WELLBORES_PAGE,
});

export const clearWellbores = () => ({
  type: WELLBORE_ACTIONS.CLEAR_WELLBORES,
});

export const receiveAndSetWellboreDetailId =
  (wellboreId, wellboreDetail) => (dispatch, getState) => {
    const { wellboreDetailId } = wellboreDetail;

    dispatch(receiveWellboreDetail(wellboreDetail));

    const currentWellbore = getWellboreByIdFromState(wellboreId)(getState());

    // do not fetch wellbore, update wellboreDetailId on existing one instead
    dispatch(
      updateWellbore(
        wellboreId,
        currentWellbore.set('wellboreDetailId', wellboreDetailId),
      ),
    );
  };

export const updateWellboreDetail =
  (fieldId, wellboreId, updatedWellboreDetail) => (dispatch) => {
    const payload = wellboreDetailService
      .updateWellboreDetail(fieldId, wellboreId, updatedWellboreDetail)
      .then((wellboreDetail) =>
        dispatch(receiveAndSetWellboreDetailId(wellboreId, wellboreDetail)),
      );

    dispatch({
      payload,
      type: WELLBORE_ACTIONS.UPDATE_WELLBORE_DETAIL,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Saving changes...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'The well details was successfully updated',
      },
    });
  };

export const createWellboreDetail = (fieldId, wellboreId) => (dispatch) => {
  const payload = wellboreDetailService
    .createWellboreDetail(fieldId, wellboreId)
    .then((wellboreDetail) =>
      dispatch(receiveAndSetWellboreDetailId(wellboreId, wellboreDetail)),
    );

  dispatch({
    payload,
    type: WELLBORE_ACTIONS.CREATE_WELLBORE_DETAIL,
  });
};

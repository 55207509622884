import Agent from 'altus-agent';

import config from 'infrastructure/config';
import authSingleton from 'services/auth.service';

const initialize = async () => {
  const msalInstance = await authSingleton.getMsalInstance();
  return new Agent(config, msalInstance);
};

const httpAgent = {
  throttledRefreshToken: async () => {
    const altusAgent = await initialize();
    return altusAgent.throttledRefreshToken;
  },
  delete: async (url) => {
    const altusAgent = await initialize();
    return await altusAgent.delete(url);
  },
  get: async (url, query) => {
    const altusAgent = await initialize();
    return await altusAgent.get(url, query);
  },
  post: async (url, body) => {
    const altusAgent = await initialize();
    return await altusAgent.post(url, body);
  },
  put: async (url, body) => {
    const altusAgent = await initialize();
    return await altusAgent.put(url, body);
  },
  file: {
    post: async (url, file, fields) => {
      const altusAgent = await initialize();
      return await altusAgent.file.post(url, file, fields);
    },
    get: async (url, query) => {
      const altusAgent = await initialize();
      return await altusAgent.file.get(url, query);
    },
  },
  files: {
    post: async (url, files, fields) => {
      const altusAgent = await initialize();
      return await altusAgent.files.post(url, files, fields);
    },
  },
  image: {
    get: async (url) => {
      const altusAgent = await initialize();
      return await altusAgent.image.get(url);
    },
  },
};

export default httpAgent;

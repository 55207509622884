import { Field } from 'formik';
import { Button } from '@material-ui/core';
import { useMemo, useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';

import { Table } from 'altus-ui-components';

import { Status } from 'features/data-exchange/components/StatusIndicator';
import { CheetahJobSource } from 'features/projects/dashboard/dashboard.constants';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import CheetahJobStatusIndicator from 'features/data-exchange/components/CheetahJobStatusIndicator';
import CheetahJobProjectSelectField from 'features/data-exchange/components/CheetahJobProjectSelectField';

const defaultInitialState = {
  pageSize: 50,
};

const FormFields = {
  PROJECT_ID: 'projectId',
};

const CompletableJobSources = [
  CheetahJobSource.WARRIOR,
  CheetahJobSource.SM_LOCATION,
];

const CheetahJobsTable = ({
  disabled,
  cheetahJobs,
  updateCheetahJob,
  cheetahJobSource,
}) => {
  const initialState = useMemo(
    () => ({
      ...defaultInitialState,
      hiddenColumns:
        disabled || !CompletableJobSources.includes(cheetahJobSource)
          ? ['actions']
          : [],
    }),
    [disabled, cheetahJobSource],
  );

  const columns = useMemo(
    () => [
      {
        xs: 2,
        id: 'name',
        accessor: (cheetahJob) => cheetahJob.get('displayName'),
        Header: () => {
          switch (cheetahJobSource) {
            case CheetahJobSource.CHEETAH:
              return 'Cheetah Project Name';
            case CheetahJobSource.SM_LOCATION:
              return 'Winch Comms unit';
            case CheetahJobSource.WARRIOR:
              return 'Warrior';
            default:
              return 'Name';
          }
        },
        Cell: ({ row, value }) => (
          <Grid container alignItems="center" wrap="nowrap">
            <Box marginRight={0.5}>
              <CheetahJobStatusIndicator cheetahJob={row.original} />
            </Box>
            {value}
          </Grid>
        ),
      },
      {
        xs: 2,
        Header: 'Collaborate Project',
        accessor: (cheetahJob) => cheetahJob.get('projectId'),
        Cell: ({ row }) => {
          const cheetahJob = row.original;

          const projectId = cheetahJob.get('projectId');

          return (
            <Grid container alignItems="center" wrap="nowrap">
              {!disabled && (
                <Box marginRight={0.5}>
                  <CheetahJobStatusIndicator
                    cheetahJob={cheetahJob}
                    status={projectId ? undefined : Status.WARNING} // if CheetahJob has no DWI Project connected, display Warning indicator
                  />
                </Box>
              )}
              <Field
                disabled={disabled || projectId}
                cheetahJob={cheetahJob}
                name={FormFields.PROJECT_ID}
                component={CheetahJobProjectSelectField}
              />
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Client',
        accessor: (cheetahJob) =>
          cheetahJob.getIn(['project', 'organizationName'], null),
      },
      {
        xs: 2,
        Header: 'Facility',
        accessor: (cheetahJob) =>
          cheetahJob.getIn(['project', 'facilityName'], null),
      },
      {
        xs: 2,
        Header: 'Well',
        accessor: (cheetahJob) =>
          cheetahJob.getIn(['project', 'wellboreName'], null),
      },
      {
        xs: 2,
        Header: 'Objective',
        accessor: (cheetahJob) => cheetahJob.getIn(['project', 'title'], null),
      },
      {
        xs: 2,
        Header: 'Sensors',
        accessor: (cheetahJob) => cheetahJob.get('sensorCount'),
      },
      {
        xs: 2,
        Header: 'Status',
        accessor: (cheetahJob) => {
          if (cheetahJob.get('isCompleted')) return 'Completed';

          return cheetahJob.get('projectId')
            ? cheetahJob.get('statusName')
            : 'Connect!';
        },
      },
      {
        xs: 2,
        id: 'actions',
        Cell: ({ row }) => {
          const cheetahJob = row.original;

          if (cheetahJob.get('isCompleted')) return null;

          // disable for non connected projects by design, to be checked with Rolf
          // https://dev.azure.com/AltusIntervention/2cd8620a-4464-42b6-b12a-44a48e3b743e/_apis/wit/attachments/56e30891-947e-4236-92ca-d9345ed3e04f?fileName=image.png
          if (
            !cheetahJob.get('projectId') &&
            cheetahJobSource !== CheetahJobSource.SM_LOCATION
          )
            return null;

          return (
            <Button
              size="small"
              color="default"
              title="Complete"
              variant="outlined"
              onClick={() =>
                updateCheetahJob(cheetahJob.set('isCompleted', true).toJS())
              }
            >
              Complete
            </Button>
          );
        },
      },
    ],
    [disabled, cheetahJobSource, updateCheetahJob],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <EditableTableRowFormik
        {...props}
        timeout={0}
        onSubmit={updateCheetahJob}
      />
    ),
    [updateCheetahJob],
  );

  return (
    <Table
      usePagination
      columns={columns}
      items={cheetahJobs}
      useGlobalFilter={false}
      initialState={initialState}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

export default CheetahJobsTable;

// Generic MultiStepForm Component
import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import {
  IntegrityForm,
  INTEGRITY_TYPE_SELECTOR,
} from '../helpers/integrityManagement.constants';
import LoadingButton from 'app/components/LoadingButton';

const MultiStepForm = ({
  initialValues,
  validationSchemas,
  onSubmit,
  renderStepContent,
  classes,
  integrityType,
  setIntegrityType,
  isEdit = false,
  isParentIdSet,
  submitForm,
}) => {
  const steps = ['Information', 'Location', 'Weight'];
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = (values, actions) => {
    if (isLastStep) {
      onSubmit(values);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
      actions.setTouched({});
    }
    actions.setSubmitting(false);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Grid>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchemas[activeStep]}
        onSubmit={handleNext}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <Stepper activeStep={activeStep} className={classes?.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {/* Disable selection when editiing */}
            {activeStep === 0 && !isEdit && (
              <Grid item xs={3}>
                <TextField
                  select
                  label="Type"
                  name={IntegrityForm.INTEGRITY_TYPE}
                  value={integrityType}
                  onChange={(e) => setIntegrityType(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={INTEGRITY_TYPE_SELECTOR.RULE}>Rule</MenuItem>
                  <MenuItem value={INTEGRITY_TYPE_SELECTOR.CATEGORY}>
                    Category
                  </MenuItem>
                </TextField>
              </Grid>
            )}

            {renderStepContent(activeStep)}

            <Grid className={classes.bottomButtonContainer}>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <LoadingButton
                disabled={
                  (!isValid || isEdit ? 0 : !dirty || isSubmitting) ||
                  activeStep === 1
                    ? !isParentIdSet
                    : false
                }
                type="submit"
                submitting={submitForm}
              >
                {isLastStep ? (isEdit ? 'Update' : 'Submit') : 'Next'}
              </LoadingButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default MultiStepForm;

import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';

const VerticalProgressContainer = withStyles({
  root: {
    height: '20px',
    width: '100%',
    background: 'linear-gradient(to left, #f00, #0f0)',
    position: 'relative',
  },
})(Box);

const Marker = withStyles({
  root: {
    position: 'absolute',
    top: '0',
    height: '20px',
    width: '4px',
    backgroundColor: '#fff',
  },
})(Box);

const IntegrityMatrixDetailsComponent = ({ value, title }) => {
  const markerPosition = `${value}%`;

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography
        style={{ color: '#fff', fontSize: '16px', marginBottom: '8px' }}
      >
        {title}
      </Typography>
      <VerticalProgressContainer>
        <Marker style={{ left: markerPosition }} />
        <Typography
          style={{
            position: 'absolute',
            top: '50%',
            left: `calc(${markerPosition} + 10px)`,
            transform: 'translateY(-50%)',
            color: '#fff',
            fontSize: '14px',
          }}
        >
          {value}%
        </Typography>
      </VerticalProgressContainer>
      {/* </Box> */}
    </Box>
  );
};

export default IntegrityMatrixDetailsComponent;

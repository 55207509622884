import Agent from 'infrastructure/agent';
import { saveFile } from 'utils/app.util';

const searchM3Items = (search) => Agent.post('/m3/search', search);

const findM3ItemsBySerialNumber = (search) => Agent.post('/m3/search', search);

const uploadSurfaceEquipmentImage = (file, meta = {}) =>
  Agent.file.post('/equipment/image/upload', file, meta);

const deleteSurfaceEquipmentImage = (imageId) =>
  Agent.delete(`/equipment/image/${imageId}`);

const getAllSurfaceEquipmentGroups = () => Agent.get('/equipment/groups');

const createSurfaceEquipment = (tool) => Agent.post('/equipment/create', tool);

const getAllSurfaceEquipment = () => Agent.get('/equipment');

const searchSurfaceEquipmentByGroup = (groupId) =>
  Agent.get(`/equipment/getequipment/${groupId}`);

const getSurfaceEquipmentById = (equipmentId) =>
  Agent.get(`/equipment/${equipmentId}`);

const getAssetHistoryByExternalToolId = (equipmentId, equipmentType, asset) =>
  Agent.post(`/assethistory/${equipmentId}/items/${equipmentType}`, asset);

const createTestAssetHistory = (asset, equipmentId, equipmentType) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/tests?equipmentType=${equipmentType}`,
    asset,
  );

const createBopTestAssetHistory = (asset, equipmentId, _, taskId) =>
  Agent.post(`/assethistory/${equipmentId}/items/boptests/${taskId}`, asset);

const createStringTestAssetHistory = (asset) => {
  const { taskId, toolstringId } = asset;
  return Agent.post(
    `/assethistory/items/${taskId}/${toolstringId}/stringtests`,
    asset,
  );
};

// also used for mobilisation
const createToolstringItemCopy = (taskId, toolstringId, asset) => {
  return Agent.post(
    `/assethistory/items/${taskId}/${toolstringId}/toolstringitemcopy`,
    asset,
  );
};

const validateToolstringItemCopySerial = (toolstringItemCopyIds) => {
  return Agent.post(
    '/assethistory/toolstringitemcopy/validate-serial-number',
    toolstringItemCopyIds,
  );
};

const getToolstringItemCopiesForToolstring = (taskId, toolstringId) => {
  return Agent.get(
    `/assethistory/items/${taskId}/${toolstringId}/toolstringitemcopy`,
  );
};

const updateStringTestDraft = (updateDraftReport) => {
  return Agent.post(
    '/assethistory/items/stringtests/update',
    updateDraftReport,
  );
};

const createcableTorsionTestAssetHistory = (asset, equipmentId, _, taskId) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableTorsionTest/${taskId}`,
    asset,
  );

const createcableWrapTestAssetHistory = (asset, equipmentId, _, taskId) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableWrapTest/${taskId}`,
    asset,
  );

const createcableTortureTestAssetHistory = (asset, equipmentId, _, taskId) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableTortureTest/${taskId}`,
    asset,
  );

const createcableContinuityTestAssetHistory = (asset, equipmentId, _, taskId) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableContinuityTest/${taskId}`,
    asset,
  );

const createcableInsulationTestAssetHistory = (asset, equipmentId, _, taskId) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableInsulationTest/${taskId}`,
    asset,
  );

const createSil2TestAssetHistory = (asset, ___, _, taskId) =>
  Agent.post(`/assethistory/items/sil2Test/${taskId}`, asset);

const validateToolStringSerialNumbers = (toolstringId) => {
  return Agent.post(`/assethistory/${toolstringId}/validate-serial-number`);
};

const validateSurfaceEquipmentSerialNumbers = (taskId) => {
  return Agent.post(
    `/assethistory/${taskId}/validate-surface-equipment-serial-number`,
  );
};

const createMaintenanceAssetHistory = (asset, equipmentId, equipmentType) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/maintenances?equipmentType=${equipmentType}`,
    asset,
  );

const getEquipmentAssets = (equipmentId, equipmentType) => {
  return Agent.get(
    `/assethistory/${equipmentId}/items?equipmentType=${equipmentType}`,
  );
};

const getStringVerificationsForToolstring = (
  taskId,
  toolstringId,
  isMobilise = false,
) => {
  const url = `/assethistory/items/${taskId}/${toolstringId}/stringtests`;
  return Agent.get(`${url}?isMobilise=${isMobilise}`);
};

const getEquipmentByCategories = (taskId) =>
  Agent.get(`/assethistory/${taskId}/equipment/categories`);

const createCableWrapBraidedMonoTestAssetHistory = (
  asset,
  equipmentId,
  _,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableWrapBraidedMonoTest/${taskId}`,
    asset,
  );

const createCableBreakTestAssetHistory = (asset, equipmentId, _, taskId) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableBreakTest/${taskId}`,
    asset,
  );

const createCableCutMaintenanceAssetHistory = (
  asset,
  equipmentId,
  equipmentType,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableCutMaintenances/${taskId}?equipmentType=${equipmentType}`,
    asset,
  );

const createCableServiceMaintenanceAssetHistory = (
  asset,
  equipmentId,
  equipmentType,
  taskId,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableServiceMaintenances/${taskId}?equipmentType=${equipmentType}`,
    asset,
  );

const createCableSpoolingMaintenanceAssetHistory = (
  asset,
  equipmentId,
  equipmentType,
  taskId,
  drumItemNumber,
  drumSerialNumber,
  storageType,
) =>
  Agent.post(
    `/assethistory/${equipmentId}/items/cableSpoolingMaintenances/${taskId}?equipmentType=${equipmentType}&drumItemNumber=${drumItemNumber}&drumSerialNumber=${drumSerialNumber}&storageType=${storageType}`,
    asset,
  );

const getCableTestDetails = (cableId, assetInfo) =>
  Agent.post(`/assethistory/${cableId}/cable/details`, assetInfo);

const exportCableHistory = (equipmentId, serialNumber) =>
  Agent.file
    .get(`/assethistory/${equipmentId}/cable/${serialNumber}/historyReport`)
    .then(([file, filename]) => saveFile(file, filename));

// Search
const searchSurfaceEquipment = (filters) =>
  Agent.post('/equipment/search', filters);

// equipment templates
const createEquipmentTemplate = (equipmentTemplate) =>
  Agent.post('/equipment/template', equipmentTemplate);

const updateEquipmentTemplate = (equipmentTemplate) =>
  Agent.put('/equipment/template', equipmentTemplate);

const getEquipmentTemplate = (equipmentTemplateId) =>
  Agent.get(`/equipment/template/${equipmentTemplateId}`);

const searchEquiomentTemplate = (filters) =>
  Agent.post('/equipment/template/search', filters);

const equipmentService = {
  getAllSurfaceEquipment,
  uploadSurfaceEquipmentImage,
  deleteSurfaceEquipmentImage,
  createSurfaceEquipment,
  getAllSurfaceEquipmentGroups,
  searchM3Items,
  findM3ItemsBySerialNumber,
  getAssetHistoryByExternalToolId,
  getSurfaceEquipmentById,
  searchSurfaceEquipmentByGroup,
  createTestAssetHistory,
  createMaintenanceAssetHistory,
  getEquipmentAssets,
  createBopTestAssetHistory,
  createStringTestAssetHistory,
  validateToolStringSerialNumbers,
  createcableTorsionTestAssetHistory,
  createcableWrapTestAssetHistory,
  createcableTortureTestAssetHistory,
  createcableContinuityTestAssetHistory,
  createcableInsulationTestAssetHistory,
  createSil2TestAssetHistory,
  validateSurfaceEquipmentSerialNumbers,
  getStringVerificationsForToolstring,
  getEquipmentByCategories,
  createCableWrapBraidedMonoTestAssetHistory,
  createCableBreakTestAssetHistory,
  createCableCutMaintenanceAssetHistory,
  getCableTestDetails,
  createCableServiceMaintenanceAssetHistory,
  createCableSpoolingMaintenanceAssetHistory,
  exportCableHistory,
  createToolstringItemCopy,
  getToolstringItemCopiesForToolstring,
  updateStringTestDraft,
  searchSurfaceEquipment,
  validateToolstringItemCopySerial,
  createEquipmentTemplate,
  updateEquipmentTemplate,
  getEquipmentTemplate,
  searchEquiomentTemplates: searchEquiomentTemplate,
};

export default equipmentService;

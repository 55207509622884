import { useRef } from 'react';
import { Plot } from '@int/geotoolkit/plot/Plot';
import { WellBoreData } from '@int/geotoolkit/schematics/data/WellBoreData';
import { CompositeSchematicsWidget } from '@int/geotoolkit/schematics/widgets/CompositeSchematicsWidget';
import wellBoreData from './helpers/wellboreData.json';

import './wellBoreStyle.css';
import { useEffect } from 'react';

function createWellBoreData() {
  return new WellBoreData(wellBoreData);
}

const options = {
  north: { title: { text: 'Schematic 2D Well Bore' } },
  gap: {
    top: {
      visible: false,
      resizable: false,
      size: '0',
    },
    left: {
      visible: true,
      resizable: false,
      size: '30px',
    },
    right: {
      visible: true,
      resizable: false,
      size: '30px',
    },
    bottom: {
      visible: false,
      resizable: false,
      size: '0',
    },
  },
  annotationssizes: {
    south: 0,
  },
  data: {
    elements: createWellBoreData(),
  },
};

const WellBoreSchematic = () => {
  const canvasRef = useRef();
  useEffect(() => {
    if (canvasRef.current) {
      const schematicWidget = new CompositeSchematicsWidget(options);
      new Plot({
        canvaselement: canvasRef.current,
        root: schematicWidget,
      });
    }
  }, []);
  return <canvas ref={canvasRef} style={{ width: '550px', height: '800px' }} />;
};

export default WellBoreSchematic;

export const projectsBase = 'projects';

export const TOOLSTRING_PAGES = {
  EDIT: 'edit',
  OVERVIEW: 'overview',
  CREATE: 'create',
};

/**
 * @deprecated This constants didn't prove to be that useful, so are moving away from using them
 */
export const pages = {
  // projects
  projects: projectsBase,
  team: 'team',
  plan: 'plan',
  lessons: 'lessons',
  details: 'details',
  report: 'report',
  documents: 'documents',
  execute: 'execute',
  events: 'events',
  login: 'login',
  wellbore: 'well',
  sections: 'sections',
  dashboard: 'dashboard',
  task: {
    index: '',
    info: 'info',
    toolstring: 'bha',
    comments: 'comments',
    activities: 'activities',
    risk: 'risk',
    simulation: 'simulation',
    wellCondition: 'well',
    surfaceEquipment: 'surfaceequipment',
  },
  // wells
  wells: {
    events: 'events',
  },
};

const routePaths = {
  root: '/',
  projects: `/${projectsBase}`,
  createProject: `/${projectsBase}/create`,
  login: '/login',
  logout: '/logout',
  changelog: '/changelog',
  privacyPolicy: '/privacy',
  project: `/${projectsBase}/:projectId`,
  dashboard: {
    root: '/v2/projects/:projectId/dashboard',
    index: '/v2/projects/:projectId/dashboard/:projectDashboardId',
    simulation:
      '/v2/projects/:projectId/dashboard/plan/:taskId/simulation/:simulationId',
  },
  details: `/${projectsBase}/:projectId`,
  team: `/${projectsBase}/:projectId/${pages.team}`,
  mobilize: `/${projectsBase}/:projectId/mobilize`,
  projectWellbore: {
    index: '/projects/:projectId/well',
    sections: '/projects/:projectId/well/sections',
    details: {
      match: '/projects/:projectId/well/details/:projectWellboreDetailId?',
      index: '/projects/:projectId/well/details/:projectWellboreDetailId',
    },
  },
  documents: `/${projectsBase}/:projectId/${pages.documents}`,
  execute: {
    match: `/${projectsBase}/:projectId/${pages.execute}/:taskId?`,
    index: `/${projectsBase}/:projectId/${pages.execute}/:taskId`,
  },
  execution: {
    match: `/${projectsBase}/:projectId/execution/:taskId?/:section?`,
    index: `/${projectsBase}/:projectId/execution`,
    tabs: {
      activities: `/${projectsBase}/:projectId/execution/:taskId/activities`,
      toolstring: `/${projectsBase}/:projectId/execution/:taskId/toolstring`,
      simulation: {
        match: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId?/:simulationSection?`,
        index: `/${projectsBase}/:projectId/execution/:taskId/simulation`,
        tabs: {
          options: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/options`,
          parameters: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/parameters`,
          temperature: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/temperature`,
          sections: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/sections`,
          comment: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/comment`,
          toolParameters: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/tool-parameters`,
          fluids: {
            root: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/fluids`,
            match: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/fluids/:simulationFluidId?`,
            index: `/${projectsBase}/:projectId/execution/:taskId/simulation/:simulationId/fluids/:simulationFluidId`,
          },
        },
      },
      surfaceEquipment: `/${projectsBase}/:projectId/execution/:taskId/surface-equipment`,
      dataAcquisition: `/${projectsBase}/:projectId/execution/:taskId/dataacquisition`,
    },
  },
  plan: {
    index: `/${projectsBase}/:projectId/${pages.plan}`,
    create: `/${projectsBase}/:projectId/${pages.plan}/create`,
    task: {
      index: `/${projectsBase}/:projectId/${pages.plan}/:taskId`,
      match: `/${projectsBase}/:projectId/${pages.plan}/:taskId/:page?`,
      wellCondition: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.wellCondition}`,
      comments: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.comments}`,
      activities: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.activities}`,
      surfaceEquipment: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.surfaceEquipment}`,
      toolstring: {
        match: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.toolstring}`,
        index: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.toolstring}/:toolstringId`,
        edit: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.toolstring}/:toolstringId/edit`,
      },
      simulation: {
        match: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId?`,
        index: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId`,
        parameters: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId/parameters`,
        temperature: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId/temperature`,
        sections: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId/sections`,
        comment: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId/comment`,
        toolParameters: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId/tool-parameters`,
        fluids: {
          root: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId/fluids`,
          match: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId/fluids/:simulationFluidId?`,
          index: `/${projectsBase}/:projectId/${pages.plan}/:taskId/${pages.task.simulation}/:simulationId/fluids/:simulationFluidId`,
        },
      },
    },
  },
  report: `/${projectsBase}/:projectId/${pages.report}/:taskId?`,
  lessons: `/${projectsBase}/:projectId/${pages.lessons}`,
  events: `/${projectsBase}/:projectId/${pages.events}`,
  wells: {
    root: '/wells',
    index: '/field/:fieldId/wells/:wellboreId',
    details: '/field/:fieldId/wells/:wellboreId/details',
    history: '/field/:fieldId/wells/:wellboreId/history',
    events: '/field/:fieldId/wells/:wellboreId/events',
    sections: '/field/:fieldId/wells/:wellboreId/sections',
    elements: '/field/:fieldId/wells/:wellboreId/elements',
    alert: '/field/:fieldId/wells/:wellboreId/alert',
    compare: '/field/:fieldId/wells/:wellboreId/compare',
    schematic: '/field/:fieldId/wells/:wellboreId/schematic',
    integrity: {
      root: '/field/:fieldId/wells/:wellboreId/integrity',
      consequence: '/field/:fieldId/wells/:wellboreId/integrity/consequence',
      attachments:
        '/field/:fieldId/wells/:wellboreId/integrity/:integrityId/attachments',
    },
    files: '/field/:fieldId/wells/:wellboreId/files',
  },
  equipment: {
    root: '/equipment',
    index: '/equipment/:equipmentId/category/:toolCategory',
    assetHistory: '/equipment/:equipmentId/assethistory/:serialNumber',
    projectHistory: '/equipment/:equipmentId/projecthistory/:toolCategory',
  },
  cable: {
    root: '/cable',
    index: '/cable/:equipmentId',
    assetHistory: '/cable/:equipmentId/assethistory/:serialNumber',
  },
  surfaceEquipment: {
    root: '/surface',
    index: '/surface/:equipmentId',
    assetHistory: '/surface/:equipmentId/assethistory/:serialNumber',
  },
  facilities: {
    root: '/facilities',
    index: '/facilities/:facilityId',
    documents: '/facilities/:facilityId/documents',
    projectHistory: '/facilities/:facilityId/history',
    overview: '/facilities/:facilityId/overview',
    details: '/facilities/:facilityId/details',
  },
  field: {
    root: '/field',
    index: '/field/:fieldId',
    overview: '/field/:fieldId/overview',
    document: '/field/:fieldId/document',
  },
  operations: {
    root: '/operations',
    dashboards: '/operations/dashboards',
  },
  settings: {
    root: '/v2/settings',
    integrity: {
      root: '/v2/settings/integrity',
      consequence: '/v2/settings/integrity/consequence',
      new: '/v2/settings/integrity/addoredit',
      newRule: '/v2/settings/integrity/newrule',
      newCategory: '/v2/settings/integrity/newcategory',
    },
  },
  dataExchange: {
    root: '/v2/data-exchange',
    winch: {
      root: '/v2/data-exchange/winch',
      archived: '/v2/data-exchange/winch/archived',
      configuration: '/v2/data-exchange/winch/configuration',
    },
    cheetah: {
      root: '/v2/data-exchange/cheetah',
      archived: '/v2/data-exchange/cheetah/archived',
    },
    warrior: {
      root: '/v2/data-exchange/warrior',
      archived: '/v2/data-exchange/warrior/archived',
    },
    liveProjects: {
      root: '/v2/data-exchange/live-projects',
      archived: '/v2/data-exchange/live-projects/archived',
      project: {
        root: '/v2/data-exchange/live-projects/project/:projectId',
        winch: {
          root: '/v2/data-exchange/live-projects/project/:projectId/winch',
          match:
            '/v2/data-exchange/live-projects/project/:projectId/winch/:cheetahJobId?',
          index:
            '/v2/data-exchange/live-projects/project/:projectId/winch/:cheetahJobId',
        },
        cheetah: {
          root: '/v2/data-exchange/live-projects/project/:projectId/cheetah',
          match:
            '/v2/data-exchange/live-projects/project/:projectId/cheetah/:cheetahJobId?',
          index:
            '/v2/data-exchange/live-projects/project/:projectId/cheetah/:cheetahJobId',
        },
        warrior: {
          root: '/v2/data-exchange/live-projects/project/:projectId/warrior',
          match:
            '/v2/data-exchange/live-projects/project/:projectId/warrior/:cheetahJobId?',
          index:
            '/v2/data-exchange/live-projects/project/:projectId/warrior/:cheetahJobId',
        },
        masterData: '/v2/data-exchange/live-projects/project/:projectId/master',
      },
    },
  },
};

export default routePaths;

import { compose } from 'redux';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import IntegrityWeightContainer from 'features/settings/integrityManagement/integrities/IntegrityWeightContainer';
import IntegrityLocationContainer from 'features/settings/integrityManagement/integrities/IntegrityLocationContainer';
import {
  addNewIntegrityManagementIntegrity,
  updateIntegrityManagementIntegrity,
  loadIntegrityManagementIntegrities,
} from 'features/settings/integrityManagement/helpers/integrityManagement.actions';
import {
  IntegrityForm as IntegrityFormFields,
  INTEGRITY_MANAGEMENT_ACTIONS,
  INTEGRITY_TYPE_SELECTOR,
  RULE_TYPES_ENUM,
  RISK_PRINCIPLE_TYPES,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

import { getIntegrityManagementIntegritiesFromStateByRiskPrinciple } from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import MultiStepForm from 'features/settings/integrityManagement/integrities/MultiStepForm';
import IntegrityRuleInformationContainer from 'features/settings/integrityManagement/integrities/IntegrityRuleInformationContainer';
import IntegrityCategoryInformationContainer from 'features/settings/integrityManagement/integrities/IntegrityCategoryInformationContainer';
import { EMPTY_LIST } from 'app/app.constants';

import {
  hasEmptyIdItem,
  addItemToCategory,
  removeEmptyIdItems,
  removeItemsByIntegrityId,
  getCombinedTopLevelEntries,
  updateTitleForNullId,
} from 'features/settings/integrityManagement/helpers/integrityManagement.collections';
import {
  IntegrityRulesYupValidator,
  IntegrityCategoriesYupValidator,
} from 'features/settings/integrityManagement/helpers/integrityManagement.validations';
import routePaths from 'app/routePaths';

// this is a temporary solution to test is it working it will be unified with AddRuleOrCategoryContainer
const AddIntegrityContent = ({
  classes,
  editIntegrity,
  filteredIntegrities,
  dispatchLoadIntegrityManagementIntegrities,
  riskPrincipleType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [parentId, setParentId] = useState(null);
  const [newCategory, setNewCategory] = useState(null);
  const [integrities, setIntegrities] = useState(EMPTY_LIST);
  const [parentCategoriesAndDetials, setParentCategoriesAndDetails] =
    useState(EMPTY_LIST);
  const [integrityType, setIntegrityType] = useState(null);
  const [validationSchemas, setValidationSchemas] = useState(
    IntegrityRulesYupValidator,
  );
  const [submitForm, setSubmitForm] = useState(false);

  const initialFormValues = {
    [IntegrityFormFields.TITLE]: '',
    [IntegrityFormFields.PARENT_ID]: '',
    [IntegrityFormFields.WEIGHT]: 0,
    [IntegrityFormFields.SCORE]: null,
    [IntegrityFormFields.RULE_TYPE]: null,
    [IntegrityFormFields.DEFAULT_VALUE_CHECKED]: true,
    [IntegrityFormFields.VALUE]: null,
    [IntegrityFormFields.PICKLIST_OPTIONS]: [{ title: '', score: '' }],
    [IntegrityFormFields.RELATED_INTEGRITIES]: [],
    [IntegrityFormFields.INTEGRITY_TYPE]: INTEGRITY_TYPE_SELECTOR.RULE,
  };

  const [formInitialValues, setFormInitialValues] = useState(initialFormValues);

  useEffect(() => {
    if (integrityType === INTEGRITY_TYPE_SELECTOR.RULE) {
      setValidationSchemas(IntegrityRulesYupValidator);
    } else if (integrityType === INTEGRITY_TYPE_SELECTOR.CATEGORY) {
      setValidationSchemas(IntegrityCategoriesYupValidator);
    }
    // eslint-disable-next-line
  }, [integrityType]);

  // sooo we will use empty id to dinstinguish for now we can change the approach or generate new one
  const setParentIdAndFilterParentCategories = (
    newParentId,
    initialIntegrity = null,
  ) => {
    // set states
    setParentId(newParentId);

    var newIntegrities = integrities.map((item) => cloneDeep(item));

    // if it is not null/undefined add new item
    if (!isNil(newParentId)) {
      const newIntegrityObj = {
        ...newCategory,
        [IntegrityFormFields.INTEGRITY_ID]: null,
        [IntegrityFormFields.TITLE]: !isNil(initialIntegrity)
          ? initialIntegrity[IntegrityFormFields.TITLE]
          : newCategory[IntegrityFormFields.TITLE],
        [IntegrityFormFields.PARENT_ID]: newParentId,
        [IntegrityFormFields.WEIGHT]: !isNil(initialIntegrity)
          ? initialIntegrity[IntegrityFormFields.WEIGHT]
          : newCategory[IntegrityFormFields.WEIGHT],
      };
      setNewCategory(newIntegrityObj);

      // first add new item i.e. category on specified position
      addItemToCategory(newIntegrities, newParentId, newIntegrityObj);

      // then flatten the data we want to send to weight table
      let adjustedCategoriesAndDetails = getCombinedTopLevelEntries(
        newIntegrities,
        newParentId,
      );
      setParentCategoriesAndDetails(adjustedCategoriesAndDetails);
    } else {
      if (hasEmptyIdItem(integrities)) {
        newIntegrities = removeEmptyIdItems(integrities);
      }
      setParentCategoriesAndDetails(EMPTY_LIST);
    }
    setIntegrities(newIntegrities);
  };

  useEffect(() => {
    dispatchLoadIntegrityManagementIntegrities();
  }, [dispatchLoadIntegrityManagementIntegrities]);

  useEffect(() => {
    if (!filteredIntegrities.isEmpty()) {
      // for edit function
      if (!isNil(editIntegrity)) {
        const filteredIntegritiesWithoutEditIntegrity =
          removeItemsByIntegrityId(
            filteredIntegrities,
            editIntegrity.integrityId,
          );
        setIntegrities(filteredIntegritiesWithoutEditIntegrity);
      } // for adding
      else {
        setIntegrities(filteredIntegrities);
      }
    }
  }, [filteredIntegrities, editIntegrity]);

  // for validation
  const handleTitleChange = (newTitle) => {
    if (isNil(newCategory)) {
      setNewCategory({
        [IntegrityFormFields.INTEGRITY_ID]: null,
        [IntegrityFormFields.TITLE]: newTitle,
        [IntegrityFormFields.PARENT_ID]: '',
        [IntegrityFormFields.WEIGHT]: 0,
      });
    } else {
      const integrityCopy = cloneDeep(newCategory);
      integrityCopy[IntegrityFormFields.TITLE] = newTitle;
      setNewCategory(integrityCopy);

      // location is already set
      if (!isNil(parentId)) {
        var updatedIntegrities = updateTitleForNullId(integrities, newTitle);
        var updatedParentCategoriesAndDetails = updateTitleForNullId(
          parentCategoriesAndDetials,
          newTitle,
        );

        // set the state man
        setIntegrities(updatedIntegrities);
        setParentCategoriesAndDetails(updatedParentCategoriesAndDetails);
      }
    }
  };

  // mapping inital data for editing
  const mapInitialDataToFormValues = (initialData) => {
    if (isNil(initialData) || integrities.isEmpty()) return initialFormValues;

    // set is it a rule or category
    setIntegrityType(
      initialData.integrityType === INTEGRITY_TYPE_SELECTOR.CATEGORY
        ? INTEGRITY_TYPE_SELECTOR.CATEGORY
        : INTEGRITY_TYPE_SELECTOR.RULE,
    );

    // set title to prepare new object
    const initialIntegrity = {
      [IntegrityFormFields.INTEGRITY_ID]: null,
      [IntegrityFormFields.TITLE]: initialData.title || '',
      [IntegrityFormFields.PARENT_ID]: initialData.parentId || '',
      [IntegrityFormFields.WEIGHT]: initialData.weight || 0,
      [IntegrityFormFields.SCORE]: initialData.score || null,
      [IntegrityFormFields.PICKLIST_OPTIONS]: [{ title: '', score: '' }],
      [IntegrityFormFields.INTEGRITY_TYPE]:
        initialData.integrityType || INTEGRITY_TYPE_SELECTOR.RULE,
    };

    // functionallity only for the rules
    if (!initialData.integrityType && initialData.integrityDetailType) {
      // to get this we need to see the rule type if any
      initialIntegrity[IntegrityFormFields.RULE_TYPE] =
        initialData.integrityDetailType;

      // not sure in picklist option type
      initialIntegrity[IntegrityFormFields.DEFAULT_VALUE_CHECKED] =
        !isNil(initialData.value) ?? false;

      if (initialData.integrityDetailType === RULE_TYPES_ENUM.RULE_BOOL) {
        // just converting to 0 and 1 instead of bool
        initialIntegrity[IntegrityFormFields.VALUE] = isNil(initialData.value)
          ? null
          : Number(initialData.value);
      }
      // this is only for picklist
      else if (
        initialData.integrityDetailType === RULE_TYPES_ENUM.RULE_PICKLIST
      ) {
        initialIntegrity[IntegrityFormFields.PICKLIST_OPTIONS] =
          initialData.details.length === 0
            ? [{ id: '', title: '', score: '' }]
            : initialData.details.map(({ integrityId, title, score }) => ({
                id: integrityId,
                title,
                score,
              }));

        initialIntegrity[IntegrityFormFields.DEFAULT_VALUE_CHECKED] = true;

        initialIntegrity[IntegrityFormFields.VALUE] = null;
      }
      // for everything else
      else {
        initialIntegrity[IntegrityFormFields.DEFAULT_VALUE_CHECKED] =
          !isNil(initialData.value) ?? false;

        initialIntegrity[IntegrityFormFields.VALUE] = initialData.value || null;
      }
    }

    // this fill perform the preparation for tables - location and weight
    setParentIdAndFilterParentCategories(
      initialData.parentId,
      initialIntegrity,
    );

    return initialIntegrity;
  };

  useEffect(() => {
    if (
      editIntegrity &&
      !filteredIntegrities.isEmpty() &&
      !integrities.isEmpty()
    ) {
      const updatedValues = mapInitialDataToFormValues(editIntegrity);
      setFormInitialValues(updatedValues);
    } else {
      setFormInitialValues(initialFormValues); // Reset to initial values if not editing
    }
    // eslint-disable-next-line
  }, [editIntegrity, filteredIntegrities]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        if (integrityType === INTEGRITY_TYPE_SELECTOR.RULE) {
          return (
            <IntegrityRuleInformationContainer
              onTitleChange={handleTitleChange}
            />
          );
        } else if (integrityType === INTEGRITY_TYPE_SELECTOR.CATEGORY) {
          return (
            <IntegrityCategoryInformationContainer
              onTitleChange={handleTitleChange}
            />
          );
        }
        break;
      case 1:
        return (
          <IntegrityLocationContainer
            integrities={integrities}
            parentId={parentId}
            setParentId={setParentIdAndFilterParentCategories}
            integrityType={integrityType}
            isParentIdSet={parentId != null}
          />
        );
      case 2:
        return (
          <IntegrityWeightContainer
            integrities={parentCategoriesAndDetials}
            setIntegrities={setParentCategoriesAndDetails}
            integrityType={integrityType}
          />
        );
      default:
        return null;
    }
  };

  // we need to send a list in the end
  const handleSubmitForm = (formValues) => {
    if (parentId) {
      // check is this the first category to be added on this level
      setSubmitForm((prevFormState) => !prevFormState);
      if (
        parentCategoriesAndDetials ||
        parentCategoriesAndDetials !== EMPTY_LIST
      ) {
        // remove the id null one here
        const relatedIntegrities = parentCategoriesAndDetials
          .filter((item) => !isNil(item.integrityId))
          .map((item) => {
            return item.integrityType === INTEGRITY_TYPE_SELECTOR.CATEGORY
              ? {
                  integrityId: item.integrityId,
                  weight: String(item.weight), // TODO check does it must be string
                }
              : {
                  integrityId: item.integrityId,
                  integrityDetailId: item.integrityDetailId,
                  weight: String(item.weight),
                };
          })
          .toJS();
        // returnn only id and weight
        formValues[IntegrityFormFields.RELATED_INTEGRITIES] =
          relatedIntegrities;
      }

      const createOrUpdate = async () => {
        if (isNil(editIntegrity)) {
          await dispatch(addNewIntegrityManagementIntegrity(formValues));
        } else {
          formValues[IntegrityFormFields.INTEGRITY_ID] =
            editIntegrity.integrityId;
          await dispatch(updateIntegrityManagementIntegrity(formValues));
        }

        riskPrincipleType === RISK_PRINCIPLE_TYPES.WELL_CONSEQUENCE
          ? history.push(routePaths.settings.integrity.consequence)
          : history.push(routePaths.settings.integrity.root);

        setSubmitForm((prevFormState) => !prevFormState);
      };

      // create or update
      createOrUpdate();
    }
  };

  return (
    <>
      <MultiStepForm
        initialValues={formInitialValues}
        validationSchemas={validationSchemas}
        onSubmit={handleSubmitForm}
        renderStepContent={getStepContent}
        classes={classes}
        integrityType={integrityType}
        setIntegrityType={setIntegrityType}
        isEdit={!isNil(editIntegrity)}
        isParentIdSet={parentId != null}
        submitForm={submitForm}
      />
    </>
  );
};

const styles = (theme) => ({
  gridBackground: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  gridBackgroundMobile: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  backButtonIcon: {
    color: theme.palette.primary.newLight,
    width: '90%',
    height: '90%',
  },
  statusHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  containedButton: {
    minHeight: theme.spacing(8),
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
  },
  containedButtonGrey: {
    minHeight: theme.spacing(8),
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.lighter,
    color: theme.palette.secondary.dark,
  },
  stepper: {
    backgroundColor: '#303030', // TODO check what color is this in theme
  },
  bottomButtonContainer: {
    paddingTop: theme.spacing(4),
  },
});

export default compose(
  connect(
    (state, { riskPrincipleType }) => ({
      filteredIntegrities:
        getIntegrityManagementIntegritiesFromStateByRiskPrinciple(
          state,
          riskPrincipleType,
        ),
      dataState: getSummarizedDataStateFromState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
      ),
    }),
    {
      dispatchLoadIntegrityManagementIntegrities:
        loadIntegrityManagementIntegrities,
    },
  ),
  withStyles(styles),
)(AddIntegrityContent);

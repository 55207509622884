import { isIPv4 } from 'is-ip';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';

import { EMPTY_STRING } from 'app/app.constants';

export const requiredBetween = (min, max) => (value) => {
  if (isQuantity(value)) {
    return requiredQuantity(value);
  }

  if (isInvalid(value) || value < min || value > max) {
    return `Value must be between ${min} and ${max}`;
  }
};

export const required = (value) => {
  if (isQuantity(value)) {
    return requiredQuantity(value);
  }

  return isInvalid(value) ? 'Required' : undefined;
};

const isInvalid = (value) => (isNumber(value) ? isNil(value) : !value);

const isQuantity = (value) => !!value?.unit; // workaround to check if it's a quantity object.

const requiredQuantity = (quantity) => {
  const isInvalid = isNil(quantity?.value) || quantity.value === EMPTY_STRING;

  return isInvalid ? 'Required' : undefined;
};

export const createMaxLengthValidator = (maxLength) => (value) => {
  if (isNil(value)) return undefined;

  return value.length > maxLength ? `Max ${maxLength} characters` : undefined;
};

export const validateIPAddress = (value) => {
  if (!value) return undefined;

  return isIPv4(value) ? undefined : 'Invalid IP address';
};

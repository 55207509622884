import { React, useEffect, useState } from 'react';
import { compose } from 'redux';
import { List } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Grid,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import {
  RISK_PRINCIPLE_TYPES,
  WellIntegrityRiskMatrix as RiskMatrix,
  RULE_TYPES_ENUM,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { EMPTY_LIST } from 'app/app.constants';
import IntegrityRiskMatrixValueComponent from './IntegrityRiskMatrixValueComponent';
import IntegrityMatrixDetailsComponent from './IntegrityMatrixDetailsComponent';
import IntegrityMatrixComplianceComponent from './IntegrityMatrixComplianceComponent';

const IntegrityRiskMatrix = ({
  classes,
  filteredIntegrities,
  title,
  finalScore,
}) => {
  const theme = useTheme();
  const getBackgroundColor = (value) => {
    switch (value) {
      case 'Red':
        return theme.altus.components.RiskMatrix.background.red;
      case 'Yellow':
        return theme.altus.components.RiskMatrix.background.yellow;
      case 'Green':
        return theme.altus.components.RiskMatrix.background.green;
      default:
        return '';
    }
  };

  const [matrix, setMatrix] = useState(List([]));
  const [integrityScore, setIntegrityScore] = useState(0);
  const [complainaceScore, setComplainceScore] = useState(0);
  const [complaince, setCompliance] = useState([]);

  useEffect(() => {
    if (filteredIntegrities.size !== 0) {
      const integrities = filteredIntegrities.get(0);
      const grid = ruleGrid(integrities, []);
      setIntegrityScore(
        integrities.riskPrincipleScore
          ? integrities.riskPrincipleScore.score * 10
          : 0,
      );
      setMatrix(grid);
    }
  }, [filteredIntegrities]);

  useEffect(
    () => {
      let numberOfComplaince = complaince.length;
      let numberOfDocumentsAvailable = 0;
      let complaincePercentage = 0;
      complaince.length &&
        complaince.forEach(
          (rule) => rule.noOfAttachments > 0 && numberOfDocumentsAvailable++,
        );

      complaincePercentage =
        (numberOfDocumentsAvailable / numberOfComplaince) * 100;

      setComplainceScore(complaincePercentage);
    },
    // eslint-disable-next-line
    [matrix],
  );

  const ruleGrid = (data, initialGrid) => {
    const weightCategories = [0, 20, 40, 60, 80, 100]; // 5 rows
    const numRows = weightCategories.length - 1; // 5 rows (0-4)
    const numCols = 5; // 5 columns
    const minScore = 0;
    const maxScore = 100;

    const minThreshold = data.riskPrincipleThreshold.minThreshold;
    const maxThreshold = data.riskPrincipleThreshold.maxThreshold;

    const columnRange = (maxScore - minScore) / numCols; // Range of scores per column
    const rowRange = (maxScore - minScore) / numRows; // Range of weights per row

    // Initialize grid
    initialGrid = Array.from({ length: numRows }, () => Array(numCols).fill(0));

    //Complaince List
    let complainceList = [];

    // Helper function to map score thresholds
    const mapScoreThreshold = (
      score,
      minThreshold,
      maxThreshold,
      minScore,
      maxScore,
    ) => {
      if (score === null || score === undefined) return minScore;
      const normalized =
        ((score - minThreshold) / (maxThreshold - minThreshold)) *
        (maxScore - minScore);
      return Math.min(Math.max(normalized, minScore), maxScore);
    };

    // Recursive function to process nested categories
    const processCategories = (category) => {
      if (category.details.length > 0) {
        processDetails(category.details, category.weight / 100);
      }

      if (category.categories.length > 0) {
        category.categories.forEach((subCategory) =>
          processCategories(subCategory),
        );
      }
    };

    // Recursive function to process nested details
    const processDetails = (detailsArray, parentWeight = 1) => {
      detailsArray.length > 0 &&
        detailsArray.forEach((rule) => {
          complainceList = [
            ...complainceList,
            {
              title: rule.title,
              type: rule.integrityDetailType,
              noOfAttachments: rule.noOfAttachments,
            },
          ];
          if (rule.integrityDetailType === RULE_TYPES_ENUM.RULE_PICKLIST) {
            const weight = (rule.weight || 0) * parentWeight;
            const score = rule.value?.score;

            if (score !== null && score !== undefined) {
              const mappedScore = mapScoreThreshold(
                score,
                minThreshold,
                maxThreshold,
                minScore,
                maxScore,
              );

              // Calculate row and column indices
              const rowIndex = Math.floor(weight / rowRange);
              const colIndex = Math.floor(mappedScore / columnRange);

              const validRow = Math.min(rowIndex, numRows - 1);
              const validCol = Math.min(colIndex, numCols - 1);

              initialGrid[validRow][validCol] += 1;
            }
          }
        });
    };

    processCategories(data);

    setCompliance(complainceList);

    return initialGrid.reverse(); // Return updated grid
  };

  const getIntersectionNumber = (rowIndex, cellIndex) => {
    if (matrix !== EMPTY_LIST) {
      if (rowIndex === 0) cellIndex = cellIndex - 1;
      return Number(matrix[rowIndex][cellIndex - 1]);
    }
  };

  const ReversedRiskMatrix = [...RiskMatrix];
  ReversedRiskMatrix[0] = ['', ...ReversedRiskMatrix[0].slice(1).reverse()];
  for (let i = 1; i < ReversedRiskMatrix.length; i++) {
    ReversedRiskMatrix[i] = [
      ReversedRiskMatrix[i][0],
      ...ReversedRiskMatrix[i].slice(1).reverse(),
    ];
  }

  return (
    <Grid item container className={classes.riskMatrixContainer}>
      <Grid item xs={8} className={classes.riskMatrixDetails}>
        <Grid item xs={12} className={classes.riskScore}>
          <Typography color="textSecondary" variant="body2">
            Registered: 2024/11/21
          </Typography>
          <Grid item className={classes.riskScoreBox}>
            <Typography variant="h7">{`Risk score: ${finalScore}`}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.tableSpacing}>
            <TableBody>
              {matrix !== EMPTY_LIST &&
                ReversedRiskMatrix.slice(1).map((row, rowIndex) => {
                  return (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => {
                        const [symbol, name] = cell.split('-');

                        if (cell === 'N/A')
                          return (
                            <TableCell
                              key={cellIndex}
                              className={`${classes.columnWidth}`}
                            />
                          );

                        if (cellIndex === 0) {
                          return (
                            <TableCell
                              key={cellIndex}
                              className={`${classes.borderNone} ${classes.centeredContent} ${classes.roundedCell} ${classes.myToolbar} ${classes.columnWidth}`}
                            >
                              <Typography variant="h7">
                                {symbol}
                                <br />
                                {name}
                              </Typography>
                            </TableCell>
                          );
                        }

                        if (cellIndex === 1 && rowIndex === 0) {
                          return (
                            <TableCell
                              key={'consequence_matrix'}
                              className={`${classes.consequence} ${classes.borderNone} ${classes.centeredContent} ${classes.roundedCell}`}
                              style={{
                                border: 'none',
                                backgroundColor: alpha(
                                  theme.palette.common.white,
                                  0.1,
                                ),
                              }}
                              rowSpan={ReversedRiskMatrix[0].length}
                              colSpan={1}
                            >
                              <Typography variant="h6">Weight</Typography>
                            </TableCell>
                          );
                        }

                        const number = getIntersectionNumber(
                          rowIndex,
                          cellIndex,
                        );

                        return (
                          <TableCell
                            key={cellIndex}
                            className={`${classes.roundedCell}`}
                            style={{
                              textAlign: 'center',
                              verticalAlign: 'middle',
                            }}
                          >
                            <div
                              style={{
                                display: 'inline-block',
                                lineHeight: 'normal',
                                opacity: number ? 1 : 0,
                              }}
                            >
                              <IntegrityRiskMatrixValueComponent
                                color={getBackgroundColor(cell)}
                                value={number || 0}
                              />
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableRow className={classes.headerRowSpacing}>
              <TableCell
                colSpan={2}
                className={`${classes.borderNone}`}
              ></TableCell>
              <TableCell
                colSpan={ReversedRiskMatrix[0].length - 1}
                className={`${classes.centeredContent} ${classes.roundedCell} ${classes.columnWidth}`}
                style={{
                  border: 'none',
                  backgroundColor: alpha(theme.palette.common.white, 0.1),
                }}
              >
                <Typography variant="h6">Likelihood</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.headerRowSpacing}>
              {ReversedRiskMatrix[0].map((header, index) => {
                if (header === 'N/A') {
                  return <TableCell key={index} style={{ display: 'none' }} />;
                }
                const [symbol, name] = header.split('-');
                const cellClasses = [classes.roundedCell];
                cellClasses.push(
                  classes.myToolbar,
                  classes.centeredContent,
                  classes.borderNone,
                  classes.columnWidth,
                );

                if (index === 0) {
                  return (
                    <TableCell
                      key={index}
                      className={`${classes.borderNone} ${classes.columnWidth}`}
                      colSpan={2}
                    ></TableCell>
                  );
                }
                return (
                  <TableCell key={index} className={cellClasses.join(' ')}>
                    <Typography variant="h7">
                      {symbol}
                      <br />
                      {name}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </Table>
        </Grid>
      </Grid>
      <Grid item xs={9} className={classes.riskMatrixDetailsContents}>
        <IntegrityMatrixDetailsComponent
          title={
            title === RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE
              ? 'Likelihood'
              : 'Consequence'
          }
          value={integrityScore.toFixed(2)}
        />
      </Grid>

      <Grid item xs={9} className={classes.riskMatrixDetailsContents}>
        <IntegrityMatrixComplianceComponent
          value={complainaceScore.toFixed(2)}
        />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  myToolbar: {
    borderRadius: '4px',
    border: 'none',
  },
  tableSpacing: {
    width: '100%',
    maxWidth: '100%',
  },
  columnWidth: {
    width: '100%',
  },
  roundedCell: {
    border: '0.5px solid white',
  },
  coloredCell: (props) => ({
    backgroundColor: props.color,
  }),
  centeredContent: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  consequence: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  borderNone: {
    border: 'none',
  },
  headerRowSpacing: {
    borderSpacing: '0px !important',
  },
  riskMatrixContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  riskMatrixDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  riskMatrixDetailsContents: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(15),
  },
  riskMatrixDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  riskScore: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: theme.spacing(1),
  },
  riskScoreBox: {
    background: alpha(theme.palette.primary.newLight, 0.12),
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(1),
  },
});

export default compose(withStyles(styles))(IntegrityRiskMatrix);

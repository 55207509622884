import { compose } from 'redux';
import { connect } from 'react-redux';
import { memo, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import {
  ApplicationBase,
  ConfirmationDialog,
  ErrorBoundary,
  NotificationContainer,
} from 'altus-ui-components';
import {
  getCurrentThemeFromState,
  getCurrentUserFromState,
} from 'app/app.selectors';
import themes from 'layout/themes';
import routePaths from 'app/routePaths';
import AppContainer from 'app/AppContainer';
import {
  APP_NAME,
  APP_VERSION,
  APP_VERSION_STORAGE_KEY,
  ERROR_CODE_WINDOW_POP_UP_DISABLED,
} from 'app/app.constants';
import AppContainerV2 from 'app/AppContainerV2';
import authSingleton from 'services/auth.service';
import AppFailure from 'app/components/AppFailure';
import { acquireToken, deleteMsalFromLocalStorage } from 'utils/app.util';
import {
  autoLogin,
  saveRedirectPath,
  newAppVersionNotification,
  onLoad,
  setApplicationFailure,
} from 'app/app.actions';
import ApplicationEnvironmentTopbar from 'app/components/ApplicationHeader/ApplicationEnvironmentTopbar';
import { isMobile } from 'react-device-detect';
import { toLogin } from 'utils/route.util';

const renderErrorContent = (error) => (
  <AppFailure errorMessage={error.message} />
);

const ApplicationRoot = ({
  theme,
  dispatchSaveRedirectPath,
  dispatchOnLoad,
  dispatchAutoLogin,
  dispatchSetApplicationFailure,
  dispatchNewAppVersionNotification,
}) => {
  const location = useLocation();
  const saveIncomingPath = () => dispatchSaveRedirectPath(location.pathname);
  useEffect(
    () => {
      const IsPopupAllowed = (ex) =>
        ex.errorCode !== ERROR_CODE_WINDOW_POP_UP_DISABLED;

      async function authenticate() {
        const msalInstance = await authSingleton.getMsalInstance();
        const isOriginRootPath = window.location.pathname === routePaths.root;

        acquireToken(msalInstance, saveIncomingPath)
          .then((resp) => {
            msalInstance.setActiveAccount(resp.account);
            dispatchAutoLogin();
          })
          .then(() => {
            const previousAppVersion = localStorage.getItem(
              APP_VERSION_STORAGE_KEY,
            );
            if (
              !isMobile &&
              (!previousAppVersion || previousAppVersion !== APP_VERSION)
            ) {
              dispatchNewAppVersionNotification();
            }
          })
          .catch((ex) => {
            console.error('Exception caught in AppRoot login chain', ex);
            deleteMsalFromLocalStorage();
            if (IsPopupAllowed(ex) && isOriginRootPath) {
              window.location = toLogin();
            } else {
              dispatchOnLoad();
            }
          });
      }

      authenticate();
    }, // eslint-disable-next-line
    [dispatchOnLoad, dispatchAutoLogin, dispatchNewAppVersionNotification],
  );

  return (
    <ApplicationBase appName={APP_NAME} theme={themes[theme]}>
      <ErrorBoundary
        onError={dispatchSetApplicationFailure}
        renderContent={renderErrorContent}
      >
        <ApplicationEnvironmentTopbar />
        <Switch>
          <Route path="/v2" component={AppContainerV2} />
          <Route component={AppContainer} />
        </Switch>
        <ConfirmationDialog />
        <NotificationContainer />
      </ErrorBoundary>
    </ApplicationBase>
  );
};

export default compose(
  connect(
    (state) => ({
      theme: getCurrentThemeFromState(state),
      currentUser: getCurrentUserFromState(state),
    }),
    {
      dispatchSaveRedirectPath: saveRedirectPath,
      dispatchOnLoad: onLoad,
      dispatchAutoLogin: autoLogin,
      dispatchSetApplicationFailure: setApplicationFailure,
      dispatchNewAppVersionNotification: newAppVersionNotification,
    },
  ),
  memo,
)(ApplicationRoot);

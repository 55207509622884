import { useMemo } from 'react';
import { compose } from 'redux';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';

import routePaths from 'app/routePaths';
import LoadingDimmer from 'app/components/LoadingDimmer';
import LoginContainer from 'features/login/LoginContainer';
import LogoutContainer from 'features/login/LogoutContainer';
import RoutesContainer from 'app/components/RoutesContainer';
import NavigationAppBar from 'app/components/NavigationAppBar';
import { getApplicationLoadedFromState } from 'app/app.selectors';
import WellboresContainer from 'features/wells/WellboresContainer';
import ProjectsContainer from 'features/projects/ProjectsContainer';
import rootRoutes, {
  equipmentRoutes,
  facilitiesRoutes,
  operationsRoutes,
  surfaceEquipmentRoutes,
  testingRoute,
  cableEquipmentRoutes,
  wellsRoutes,
  fieldRoutes,
} from 'app/routes';
import ProjectContainer from 'features/projects/components/ProjectContainer';
import ApplicationHeaderContainer from 'app/components/ApplicationHeader/ApplicationHeaderContainer';
import FacilitiesContainer from 'features/facilities/FacilitiesContainer';
import EquipmentContainer from 'features/equipment/EquipmentContainer';
import ChangeLog from 'features/changelog';
import { useViewMode } from 'app/hooks/useViewMode';
import MobileApplicationHeader from 'app/components/ApplicationHeader/Mobile/MobileApplicationHeader';
import mobileRoutes, { projectRoutes } from 'app/mobileRoutes';
import ListIcon from '@material-ui/icons/List';
import BallotIcon from '@material-ui/icons/Ballot';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import MobileProjectOverviewContainer from 'app/components/Mobile/MobileProjectOverviewContainer';
import MobileNavigationAppBar from 'app/components/Mobile/MobileNavigationAppBar';
import MobileProjectsListContainer from 'app/components/Mobile/MobileProjectsListContainer';
import ProjectStatusContainer from 'features/mobile/projects/project/ProjectStatusContainer';
import MobileProjectExecuteContainer from 'app/components/Mobile/MobileProjectExecuteContainer';
import MobileTaskDetailsContainer from 'app/components/Mobile/MobileTaskDetailsContainer';
import PrivacyPolicy from 'features/privacypolicy/PrivacyPolicy';

const AppContainer = ({ appLoaded }) => {
  const viewMode = useViewMode();
  const mobileRoute = useMemo(
    () => [
      {
        exact: true,
        path: routePaths.projects,
        component: MobileProjectsListContainer,
        breadcrumb: 'Projects',
      },
      {
        exact: true,
        path: routePaths.details,
        breadcrumb: 'Project',
        Icon: <BallotIcon />,
        component: ProjectStatusContainer,
      },
      {
        exact: true,
        path: '/plans',
        breadcrumb: 'Plan',
        Icon: <ListIcon />,
        component: MobileProjectOverviewContainer,
      },
      {
        exact: true,
        path: '/projects/:projectId/execution',
        breadcrumb: 'Execute',
        Icon: <PlayCircleFilledIcon />,
        component: MobileProjectExecuteContainer,
      },
      {
        exact: true,
        path: '/projects/:projectId/execution/:taskId/:section',
        routes: [],
        component: MobileTaskDetailsContainer,
      },
      {
        exact: true,
        path: routePaths.privacyPolicy,
        component: PrivacyPolicy,
      },
      {
        exact: true,
        path: routePaths.login,
        component: LoginContainer,
      },
      {
        exact: true,
        path: routePaths.logout,
        component: LogoutContainer,
      },
      {
        path: routePaths.root,
        exact: true,
        redirectTo: routePaths.projects,
      },
      {
        path: routePaths.operations.root,
        exact: true,
        redirectTo: routePaths.projects,
      },
      {
        exact: true,
        path: '/projects/:projectId/status',
        breadcrumb: 'Project Status',
        component: ProjectStatusContainer,
      },
      ...projectRoutes,
    ],
    [],
  );
  const routes = useMemo(
    () => [
      {
        exact: true,
        path: routePaths.login,
        component: LoginContainer,
      },
      {
        exact: true,
        path: routePaths.logout,
        component: LogoutContainer,
      },
      {
        exact: true,
        path: routePaths.changelog,
        component: ChangeLog,
      },
      {
        exact: true,
        path: routePaths.privacyPolicy,
        component: PrivacyPolicy,
      },
      {
        exact: true,
        path: routePaths.wells.root,
        component: WellboresContainer,
        breadcrumb: 'Wells',
      },
      {
        exact: true,
        path: routePaths.projects,
        component: ProjectsContainer,
        breadcrumb: 'Projects',
      },
      {
        exact: true,
        path: routePaths.equipment.root,
        component: EquipmentContainer,
        breadcrumb: 'Equipment',
      },
      {
        exact: true,
        path: routePaths.facilities.root,
        component: FacilitiesContainer,
        breadcrumb: 'Facilities',
      },
      {
        path: routePaths.project,
        component: ProjectContainer,
      },
      ...equipmentRoutes,
      ...cableEquipmentRoutes,
      ...surfaceEquipmentRoutes,
      ...facilitiesRoutes,
      ...wellsRoutes,
      ...operationsRoutes,
      ...testingRoute,
      ...fieldRoutes,
      {
        path: routePaths.root,
        exact: true,
        redirectTo: routePaths.operations.root,
      },
    ],
    [],
  );

  return (
    <>
      {viewMode === 'mobile' ? (
        <MobileApplicationHeader />
      ) : (
        <ApplicationHeaderContainer />
      )}
      {!appLoaded && <LoadingDimmer text="Loading application" />}
      {appLoaded && (
        <>
          {viewMode === 'mobile' ? (
            <>
              <RoutesContainer routes={mobileRoute} />
              <MobileView>
                <Route
                  exact
                  path={[
                    routePaths.details,
                    '/plans',
                    '/projects/:projectId/execution',
                    '/projects/:projectId/execution/:taskId/:section',
                  ]}
                >
                  <MobileNavigationAppBar routesToDisplay={mobileRoutes} />
                </Route>
              </MobileView>
            </>
          ) : (
            <>
              <RoutesContainer routes={routes} />
              <BrowserView>
                <Route
                  exact
                  path={[
                    routePaths.projects,
                    routePaths.wells.root,
                    routePaths.operations.root,
                    routePaths.facilities.root,
                    routePaths.equipment.root,
                    routePaths.changelog,
                  ]}
                >
                  <NavigationAppBar
                    routes={rootRoutes.filter((route) => !route.sandbox)}
                  />
                </Route>
              </BrowserView>
            </>
          )}
        </>
      )}
    </>
  );
};

export default compose(
  connect((state) => ({
    appLoaded: getApplicationLoadedFromState(state),
  })),
)(AppContainer);

import moment from 'moment';
import { useMemo } from 'react';
import { Table, TableRowActionsCell } from 'altus-ui-components';
import { IconButton } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import PreviewIcon from '@material-ui/icons/FindInPage';
import { EMPTY_LIST } from 'app/app.constants';
import { ATTACHMENTS_DATE_FORMAT } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

const IntegrityAttachmentsTable = ({ attachments }) => {
  const columns = useMemo(
    () => [
      {
        xs: 6,
        Header: 'Document',
        accessor: (attachment) => attachment.fileName,
      },
      {
        xs: 3,
        Header: 'Logged date',
        accessor: (attachment) =>
          moment(attachment.reportedDate).format(ATTACHMENTS_DATE_FORMAT),
      },
      {
        xs: 3,
        id: 'edit',
        Cell: ({ row }) => {
          const integrity = row.original;

          if (
            !integrity &&
            (!integrity.integrityType || !integrity.integrityRuleType)
          ) {
            return null;
          }

          return (
            <TableRowActionsCell minItems={1}>
              <IconButton color="default">
                <PreviewIcon />
              </IconButton>
              <IconButton color="default">
                <EditIcon />
              </IconButton>
              <IconButton color="default">
                <CloseIcon />
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [],
  );

  return (
    <Table
      useExpanded
      subrowOffset={3}
      items={attachments ?? EMPTY_LIST}
      columns={columns}
      useGlobalFilter={true}
      noItemsMessage="Rule not compliant Document(s) missing"
    />
  );
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[700],
  },
});

export default withStyles(styles)(IntegrityAttachmentsTable);

import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Drawer } from '@material-ui/core';
import UploadIntegrityAttachmentDrawerHeader from 'features/wells/integrity/attachments/upload/UploadIntegrityAttachmentDrawerHeader';
import UploadIntegrityAttachmentDrawerContent from 'features/wells/integrity/attachments/upload/UploadIntegrityAttachmentDrawerContent';

const UploadIntegrityAttachmentDrawer = ({
  isOpen,
  classes,
  attachments,
  toggleDrawer,
  projectFiles,
  wellboreFiles,
  handleUpdateAttachments,
  isSubmitting,
  setIsSubmitting,
}) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Grid className={classes.drawerHeader}>
        <UploadIntegrityAttachmentDrawerHeader
          toggleDrawer={toggleDrawer}
          isSubmitting={isSubmitting}
        />
      </Grid>
      <Grid className={classes.drawerContent}>
        <UploadIntegrityAttachmentDrawerContent
          toggleDrawer={toggleDrawer}
          attachments={attachments}
          wellboreFiles={wellboreFiles}
          projectFiles={projectFiles}
          handleUpdateAttachments={handleUpdateAttachments}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      </Grid>
    </Drawer>
  );
};

const styles = (theme) => ({
  drawerPaper: {
    width: '30%',
    minWidth: 300,
    background: theme.palette.background.default,
  },
  drawerHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    background: theme.palette.background.default,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.table.row.hover}`,
  },
  drawerContent: {
    padding: theme.spacing(2),
    overflowY: 'scroll-y',
    margin: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
  },
});

export default withStyles(styles)(UploadIntegrityAttachmentDrawer);

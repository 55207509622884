import { useLocation } from 'react-router';
import { BasePageHeader } from 'altus-ui-components';
import { NavTabsContainer } from 'altus-ui-components';
import SystemRoutesContainer from 'app/components/SystemRoutesContainer';

const WellboreIntegrityContainer = ({ Icon, breadcrumb, routes }) => {
  const location = useLocation();
  const hideTabs = location.pathname.includes('attachments');

  return (
    <>
      {!hideTabs && <BasePageHeader title={breadcrumb} Icon={Icon} />}
      {!hideTabs && <NavTabsContainer routes={routes} />}
      <SystemRoutesContainer routes={routes} />
    </>
  );
};

export default WellboreIntegrityContainer;

import * as yup from 'yup';
import moment from 'moment/moment';

// Utility function to check if a date is in a range
const isDateInRange = (value, startDate, endDate) => {
  if (
    !moment(value).isValid() ||
    !moment(startDate).isValid() ||
    !moment(endDate).isValid()
  ) {
    return false;
  }

  // Ensure all dates are treated consistently in the local time zone
  const momentValue = moment.utc(value);
  const momentStartDate = moment.utc(startDate);
  const momentEndDate = moment.utc(endDate);
  return momentValue.isBetween(momentStartDate, momentEndDate, null, '[]');
};
const isValidDate = (date) => date && moment(date).isValid();

const startTimeValidation = {
  startTime: yup
    .date()
    .typeError('Start Date is required')
    .required('Start Date is required')
    .when(
      [
        'endTime',
        'previousActivityStartTime',
        'hasWaitCodes',
        'nextActivityStartTime',
        'previousActivityAdjustedStartTime',
        'nextActivityAdjustedStartTime',
        'previousWaitingActivityStartTime',
        'previousWaitingActivityEndTime',
      ],
      (
        endTime,
        previousActivityStartTime,
        hasWaitCodes,
        nextActivityStartTime,
        previousActivityAdjustedStartTime,
        nextActivityAdjustedStartTime,
        previousWaitingActivityStartTime,
        previousWaitingActivityEndTime,
      ) => {
        let baseValidation = yup
          .date()
          .typeError('Start Date is required')
          .required('Start Date is required')
          .test(
            'is-start-date-future',
            "Start Date can't be in the future",
            (value) => value < new Date(),
          );
        baseValidation = baseValidation.test(
          'is-in-previous-waiting-range',
          'Start date must not fall within the duration of the previous waiting activity',
          function (value) {
            if (
              isValidDate(previousWaitingActivityStartTime) &&
              isValidDate(previousWaitingActivityEndTime)
            ) {
              return !isDateInRange(
                value,
                previousWaitingActivityStartTime,
                previousWaitingActivityEndTime,
              );
            }

            return true;
          },
        );

        if (isValidDate(previousWaitingActivityEndTime)) {
          return baseValidation.test(
            'is-start-after-wait-code-end',
            'Start date must be after the end of the previous wait code',
            function (value) {
              return moment(value).isAfter(
                moment(previousWaitingActivityEndTime),
              );
            },
          );
        }

        if (
          hasWaitCodes &&
          isValidDate(nextActivityStartTime) &&
          isValidDate(previousActivityStartTime)
        ) {
          return baseValidation
            .max(
              nextActivityStartTime,
              'Start date must be before start date of next activity or end of wait code',
            )
            .min(
              previousActivityStartTime,
              'Start date must be after start of previous activity',
            );
        }
        if (
          hasWaitCodes &&
          isValidDate(nextActivityStartTime) &&
          isValidDate(previousActivityStartTime)
        ) {
          return baseValidation
            .max(
              nextActivityStartTime,
              'Start date must be before start date of next activity or end of wait code',
            )
            .min(
              previousActivityStartTime,
              'Start date must be after start of previous activity',
            );
        }

        if (
          hasWaitCodes &&
          !previousActivityStartTime &&
          isValidDate(nextActivityStartTime)
        ) {
          return baseValidation.max(
            nextActivityStartTime,
            'Start date must be before start date of next activity or end of wait code',
          );
        }

        if (isValidDate(endTime) && isValidDate(previousActivityStartTime)) {
          return baseValidation
            .max(
              endTime,
              'Start date must be before start date of next activity or end of wait code',
            )
            .min(
              previousActivityStartTime,
              'Start date must be after start of previous activity',
            );
        }
        if (!previousActivityStartTime && isValidDate(endTime)) {
          return baseValidation.max(
            endTime,
            'Start date must be before start date of next activity',
          );
        }
        if (!endTime && isValidDate(previousActivityStartTime)) {
          return baseValidation.min(
            previousActivityStartTime,
            'Start date must be after start date of previous activity',
          );
        }

        if (
          isValidDate(previousActivityStartTime) &&
          isValidDate(nextActivityStartTime)
        ) {
          return baseValidation.test(
            'is-next-after-previous',
            'Start date must be after previous activity start date',
            () =>
              moment(nextActivityStartTime).isAfter(previousActivityStartTime),
          );
        }

        if (
          isValidDate(previousActivityAdjustedStartTime) &&
          isValidDate(nextActivityAdjustedStartTime)
        ) {
          return baseValidation
            .max(
              nextActivityAdjustedStartTime,
              'Start date should not be  between the duration of the previous waiting activity',
            )
            .min(
              previousActivityAdjustedStartTime,
              'Start date should not be  between the duration of the previous waiting activity',
            );
        }

        return baseValidation;
      },
    ),
};

const endTimeValidationSpecific = {
  endTime: yup
    .date()
    .typeError('End date is required')
    .required('End date is required')
    .when(
      [
        'startTime',
        'nextActivityStartTime',
        'isNextPausedWaitingActivity',
        'nextNonPausedActivityStartTime',
      ],
      (
        startTime,
        nextActivityStartTime,
        isNextPausedWaitingActivity,
        nextNonPausedActivityStartTime,
      ) => {
        let baseValidation = yup
          .date()
          .typeError('End Date is required')
          .required('End Date is required')
          .test(
            'is-start-date-future',
            "End Date can't be in the future",
            (value) => value < new Date(),
          );
        if (isValidDate(startTime)) {
          if (
            isNextPausedWaitingActivity &&
            isValidDate(nextNonPausedActivityStartTime)
          ) {
            return baseValidation
              .min(startTime, 'End date must be after start date of wait code')
              .max(
                nextNonPausedActivityStartTime,
                'End date must be before start time of following activity',
              );
          }
          if (isValidDate(nextActivityStartTime)) {
            return baseValidation
              .min(startTime, 'End date must be after start date of wait code')
              .max(
                nextActivityStartTime,
                'End date must be before start time of following activity',
              );
          }
          if (!nextActivityStartTime) {
            return baseValidation.min(
              startTime,
              'End date must be after start date of wait code',
            );
          }
        }
        return baseValidation;
      },
    ),
};

export const validationSchemaForCompletedItem = yup
  .object()
  .shape(startTimeValidation);
export const validationSchemaForCurrentItem = yup
  .object()
  .shape(startTimeValidation);
export const customEndDateValidation = yup
  .object()
  .shape(endTimeValidationSpecific);

import React, { useState, useEffect } from 'react';
import {
  Grid,
  List,
  Button,
  Divider,
  ListItem,
  Checkbox,
  Collapse,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { DEFAULT_ATTACHMENT_FILTERS } from 'features/wells/integrity/IntegrityAttachmentsContainer';

const IntegrityFilterAttachmentDrawerContent = ({
  classes,
  toggleDrawer,
  filters,
  setFilters,
}) => {
  const [newFilters, setNewFilters] = useState(DEFAULT_ATTACHMENT_FILTERS);

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  // for running update of the filters
  const submitAttachmentsFilters = () => {
    setFilters(newFilters);
    toggleDrawer();
  };

  const cancelFilters = () => {
    setFilters(filters); // reset to current filters
    toggleDrawer();
  };

  const handleToggleFileType = (key) => {
    setNewFilters((prev) => ({
      ...prev,
      fileTypes: {
        ...prev.fileTypes,
        [key]: !prev.fileTypes[key],
      },
    }));
  };

  const handleToggleYear = (year) => {
    setNewFilters((prev) => {
      const yearData = prev.years[year];
      const allMonthsSelected = !yearData.isSelected;
      const updatedMonths = Object.keys(yearData.months).reduce(
        (acc, month) => ({
          ...acc,
          [month]: allMonthsSelected,
        }),
        {},
      );

      return {
        ...prev,
        years: {
          ...prev.years,
          [year]: {
            ...yearData,
            isSelected: allMonthsSelected,
            months: updatedMonths,
          },
        },
      };
    });
  };

  const handleToggleMonth = (year, month) => {
    setNewFilters((prev) => {
      const yearData = prev.years[year];
      const updatedMonths = {
        ...yearData.months,
        [month]: !yearData.months[month],
      };

      const allMonthsSelected = Object.values(updatedMonths).every(
        (selected) => selected,
      );

      return {
        ...prev,
        years: {
          ...prev.years,
          [year]: {
            ...yearData,
            isSelected: allMonthsSelected,
            months: updatedMonths,
          },
        },
      };
    });
  };

  const handleExpandYear = (year) => {
    setNewFilters((prev) => ({
      ...prev,
      years: {
        ...prev.years,
        [year]: {
          ...prev.years[year],
          isExpanded: !prev.years[year].isExpanded,
        },
      },
    }));
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Document type filterz */}
        <Grid item xs={12}>
          <List>
            <Typography variant="subtitle1" gutterBottom>
              Document Format
            </Typography>
            {Object.keys(newFilters.fileTypes).map((type) => (
              <ListItem key={type} dense>
                <ListItemIcon>
                  <Checkbox
                    checked={newFilters.fileTypes[type]}
                    onChange={() => handleToggleFileType(type)}
                  />
                </ListItemIcon>
                <ListItemText primary={type} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Grid>
        {/* Date filterz */}
        <Grid item xs={12}>
          <List>
            <Typography variant="subtitle1" gutterBottom>
              Date
            </Typography>
            {Object.keys(newFilters.years).map((year) => (
              <React.Fragment key={year}>
                <ListItem button onClick={() => handleExpandYear(year)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={newFilters.years[year].isSelected}
                      indeterminate={
                        !newFilters.years[year].isSelected &&
                        Object.values(newFilters.years[year].months).some(
                          (selected) => selected,
                        )
                      }
                      onChange={() => handleToggleYear(year)}
                      className={classes.indeterminate}
                    />
                  </ListItemIcon>
                  <ListItemText primary={year} />
                  {newFilters.years[year].isExpanded ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={newFilters.years[year].isExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {Object.keys(newFilters.years[year].months).map((month) => (
                      <ListItem key={month} button style={{ paddingLeft: 32 }}>
                        <ListItemIcon>
                          <Checkbox
                            checked={newFilters.years[year].months[month]}
                            onChange={() => handleToggleMonth(year, month)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={month} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
          <Divider />
        </Grid>
        {/* Action Buttons */}
        <Grid item xs={12}>
          <Grid item container justifyContent="flex-end" spacing={1}>
            <Grid item xs={3}>
              <Button
                title="Cancel"
                color="secondary"
                variant="contained"
                onClick={cancelFilters}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                title="Save attachments"
                color="primary"
                variant="contained"
                onClick={submitAttachmentsFilters}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  activityField: {
    paddingTop: `${theme.spacing(3)}px`,
  },
  subActivityBox: {
    marginTop: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    border: `2px solid ${theme.palette.table.row.hover}`,
  },
  subActivityField: {
    padding: `${theme.spacing(1)}px`,
  },
  marginLabel: {
    marginTop: '4vh',
  },

  bottomLabel: {
    marginBottom: '4vh',
  },
  iconStyle: {
    height: '2.2vh',
    display: 'inline-block',
    verticalAlign: 'middle',
    objectFit: 'contain',
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  closeIcon: {
    marginLeft: 'auto',
  },
  indeterminate: {
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.primary.main, // Set the indeterminate color to primary
    },
  },
});

export default withStyles(styles)(IntegrityFilterAttachmentDrawerContent);

import withStyles from '@material-ui/styles/withStyles';

import { LogoAndAppTitle as LogoAndAppTitleCore } from 'altus-ui-components';

import routePaths from 'app/routePaths';
import config from 'infrastructure/config/index';
import { ENVIRONMENTS_NAMES, APP_SHORTNAME } from 'app/app.constants';

import Logo from 'app/components/ApplicationHeader/Logo';

const LogoAndAppTitle = ({ to = routePaths.root, classes, onClick }) => (
  <LogoAndAppTitleCore
    spacing={1}
    title={APP_SHORTNAME}
    environmentName={ENVIRONMENTS_NAMES[config.environmentName]}
    renderLogo={() =>
      onClick ? (
        <div onClick={onClick} className={classes.logo}>
          <Logo
            rootUrl={null}
            classes={{
              logo: classes.logoRoot,
            }}
          />
        </div>
      ) : (
        <Logo
          rootUrl={to}
          classes={{
            logo: classes.logoRoot,
          }}
        />
      )
    }
    classes={{
      environmentName: classes.environmentName,
    }}
  />
);

const styles = (theme) => ({
  logoRoot: {
    height: theme.spacing(4) - 2,
    '&:hover': {
      opacity: 0.5,
    },
  },
  logo: {
    cursor: 'pointer',
  },
  environmentName: {
    color: theme.palette.environment[config.environmentName],
  },
});

export default withStyles(styles)(LogoAndAppTitle);

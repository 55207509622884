import React, { useRef, useEffect } from 'react';
import { Plot } from '@int/geotoolkit3d/Plot';
import { Grid } from '@int/geotoolkit3d/scene/grid/Grid';
import { TrajectoryTube } from '@int/geotoolkit3d/scene/well/TrajectoryTube';
import { Vector3 } from 'three';
import { DefaultColorProvider } from '@int/geotoolkit/util/DefaultColorProvider';

const X_SCALE = 1;
const Y_SCALE = 1;
const Z_SCALE = 2.2;
const CAMERA_DISTANCE = -30000;
const TUBE_RADIUS = 50;

// Define a color provider for the gradient
const COLOR_PROVIDER = new DefaultColorProvider({
  values: [0, 5, 10, 15, 20],
  colors: ['blue', 'cyan', 'green', 'yellow', 'red'],
});

const generateOrderedWellData = (depth, steps) => {
  const data = {
    x: [],
    y: [],
    z: [],
    values: [],
  };

  const stepSize = depth / steps;

  for (let i = 0; i <= steps; i++) {
    data.x.push(0); // No deviation in X
    data.y.push(0); // No deviation in Y
    data.z.push(-i * stepSize); // Depth

    const progressionValue = (i / steps) * 20; // Map depth to 0–20 range
    const smudgedValue = progressionValue + (Math.random() - 0.5) * 2;
    data.values.push(smudgedValue);
  }

  return data;
};

const wellData1 = generateOrderedWellData(5000, 500);
const wellData2 = generateOrderedWellData(5000, 500);
const wellData3 = generateOrderedWellData(5000, 500);

const WellScene = ({ wellData }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    // Set camera position
    const camera = new Vector3(0, 0, -2500);

    // Create plot
    const plot = new Plot({
      container: containerRef.current,
      camera: {
        position: camera.clone().add(new Vector3(0, CAMERA_DISTANCE, 1000)),
        lookAt: camera.clone(),
      },
      scale: new Vector3(X_SCALE, Y_SCALE, Z_SCALE),
    });

    // Add grid
    const grid = new Grid({
      start: new Vector3(-1000, -1000, -5000),
      end: new Vector3(1000, 1000, 0),
      modelStart: new Vector3(-1000, -1000, -5000),
      modelEnd: new Vector3(1000, 1000, 0),
    });
    plot.getRoot().add(grid);

    // Add trajectory tube
    const trajectoryTube = new TrajectoryTube({
      data: [
        {
          x: wellData.x,
          y: wellData.y,
          z: wellData.z,
          values: wellData.values,
        },
      ],
      colorProvider: COLOR_PROVIDER,
      radius: TUBE_RADIUS,
      segments: 32,
    });
    plot.getRoot().add(trajectoryTube);

    return () => {
      plot.dispose();
    };
  }, [wellData]);

  return <div ref={containerRef} style={{ width: '100%', height: '100%' }} />;
};

const Well3DVisualization = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        gap: '20px',
      }}
    >
      {/* Render three separate scenes */}
      <div style={{ flex: 1, height: '100%' }}>
        <WellScene wellData={wellData1} />
      </div>
      <div style={{ flex: 1, height: '100%' }}>
        <WellScene wellData={wellData2} />
      </div>
      <div style={{ flex: 1, height: '100%' }}>
        <WellScene wellData={wellData3} />
      </div>
    </div>
  );
};

export default Well3DVisualization;

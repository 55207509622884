import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { Grid, IconButton, Typography } from '@material-ui/core';

const UploadIntegrityAttachmentDrawerHeader = ({
  toggleDrawer,
  isSubmitting,
}) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={11}>
        <Typography variant="h6" noWrap>
          Add file to rule
        </Typography>
      </Grid>
      <Grid container item xs={1} justifyContent="flex-end">
        <IconButton
          title="Close"
          onClick={toggleDrawer}
          disabled={isSubmitting}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default UploadIntegrityAttachmentDrawerHeader;

import { compose } from 'redux';
import { Box, Grid } from '@material-ui/core';
import { useEffect, memo, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import {
  getFieldsFromState,
  getAvailableWellStatuses,
  getWellboresPageParamsFromState,
  getWellboresGroupedByFieldFromState,
} from 'features/wells/wellbore.selectors';

import {
  getSummarizedDataStateFromState,
  getCurrentClientOrganizationIdFromState,
} from 'app/app.selectors';

import {
  EMPTY_MAP,
  EMPTY_LIST,
  EMPTY_STRING,
  PagedQueryParams,
  EUROPE_MAP_CENTER_COORDS,
  SystemPermission,
} from 'app/app.constants';

import {
  onLoad,
  updateFilter,
  clearWellbores,
  updateWellAperioFilter,
} from 'features/wells/wellbore.actions';

import { useHeader } from 'app/hooks/useHeader';
import GoogleMap from 'app/components/GoogleMap';
import usePagedSearch from 'app/hooks/usePagedSearch';
import { getAllRowsExpanded } from 'utils/table.util';
import { Filters } from 'features/wells/components/WellboresFilter';
import WellboresTable from 'features/wells/components/WellboresTable';
import WellboresFilter from 'features/wells/components/WellboresFilter';
import { WELLS_ACTIONS as WELLBORE_ACTIONS } from 'features/wells/wells.constants';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';
import AperioWellboresTable from 'features/wells/components/AperioWellboresTable';

const getWellboreSubRows = (row) => {
  if (row.original) return;

  return row.get('wellbores', EMPTY_LIST).valueSeq();
};

const defaultFormValues = {
  [Filters.FIELD_ID]: EMPTY_STRING,
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.WELL_STATUS]: EMPTY_STRING,
  [Filters.ORGANIZATION_ID]: EMPTY_STRING,
};

const pageSize = 50;
const initialPage = 1;

const WellboresContainer = ({
  fields,
  classes,
  breadcrumb,
  pageParams,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchUpdateFilter,
  currentClientOrganizationId,
  wellboresByField = EMPTY_MAP,
  availableWellStatuses = EMPTY_LIST,
  dispatchUpdateWellAperioFilter,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });

  const { userPermissions } = useSystemPermissions();

  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(
      state,
      WELLBORE_ACTIONS.GET_FIELDS,
      WELLBORE_ACTIONS.WELLS_PAGE_LOADED,
      WELLBORE_ACTIONS.UPDATE_WELL_FILTER,
    ),
  );

  useEffect(() => {
    dispatchOnLoad(currentClientOrganizationId);

    return () => dispatchOnUnload();
  }, [dispatchOnLoad, dispatchOnUnload, currentClientOrganizationId]);

  const initialTableState = useMemo(
    () => ({
      pageSize,
      pageIndex: (pageParams.get('currentPage') ?? initialPage) - 1,
      expanded: getAllRowsExpanded(wellboresByField, getWellboreSubRows),
    }),
    [pageParams, wellboresByField],
  );

  const initialFilterValues = useMemo(
    () => ({
      ...defaultFormValues,
      organizationId: currentClientOrganizationId,
    }),
    [currentClientOrganizationId],
  );

  const initialPagedSearchFilters = useMemo(
    () => ({
      [PagedQueryParams.PAGE]: initialTableState.pageIndex + 1,
      [PagedQueryParams.PAGE_SIZE]: pageSize,
      ...initialFilterValues,
    }),
    [initialTableState, initialFilterValues],
  );

  const { onPageChange, onFiltersChange } = usePagedSearch(
    userPermissions.includes(SystemPermission.WELL_ACCESS_ONLY)
      ? dispatchUpdateWellAperioFilter
      : dispatchUpdateFilter,
    initialPagedSearchFilters,
  );

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <GoogleMap center={EUROPE_MAP_CENTER_COORDS} />
      <Grid container className={classes.content} justifyContent="flex-end">
        <Grid
          item
          sm={7}
          xs={12}
          container
          component={Box}
          paddingLeft={2}
          paddingRight={2}
          direction="column"
        >
          <WellboresFilter
            fields={fields}
            onSubmit={onFiltersChange}
            initialValues={initialFilterValues}
            wellStatuses={availableWellStatuses}
          />
          {userPermissions.includes(SystemPermission.WELL_ACCESS_ONLY) ? (
            <AperioWellboresTable
              initialState={initialTableState}
              onPageChange={onPageChange}
              getSubRows={getWellboreSubRows}
              wellboresByField={wellboresByField}
              pageCount={pageParams.get('pageCount')}
            />
          ) : (
            <WellboresTable
              initialState={initialTableState}
              onPageChange={onPageChange}
              getSubRows={getWellboreSubRows}
              wellboresByField={wellboresByField}
              pageCount={pageParams.get('pageCount')}
            />
          )}
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = () => ({
  basePageChildren: {
    padding: 0,
  },
  content: {
    top: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
  },
});

export default compose(
  connect(
    (state) => ({
      fields: getFieldsFromState(state),
      wellboresByField: getWellboresGroupedByFieldFromState(state),
      availableWellStatuses: getAvailableWellStatuses(state),
      pageParams: getWellboresPageParamsFromState(state),
      currentClientOrganizationId:
        getCurrentClientOrganizationIdFromState(state),
    }),
    {
      dispatchOnLoad: onLoad,
      dispatchOnUnload: clearWellbores,
      dispatchUpdateFilter: updateFilter,
      dispatchUpdateWellAperioFilter: updateWellAperioFilter,
    },
  ),
  memo,
  withStyles(styles),
)(WellboresContainer);

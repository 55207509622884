import Agent from 'infrastructure/agent';
import integrityManagementMappers from 'features/settings/integrityManagement/helpers/integrityManagement.mappers';

// These Endpoints will change according to the risk principles
const getIntegrityManagementIntegrities = () =>
  Agent.get('/integritymanagement');

const getIntegrityManagementIntegrity = (integrityId) =>
  Agent.get(`/integritymanagement/${integrityId}`);

const addNewIntegrityManagementIntegrity = (integrity) =>
  Agent.post(
    '/integritymanagement',
    integrityManagementMappers.integrities.to(integrity),
  );

const updateIntegrityManagementIntegrity = (integrity) =>
  Agent.put(
    '/integritymanagement',
    integrityManagementMappers.integrities.to(integrity),
  );

const deleteIntegrityManagementIntegrity = (integrityId) =>
  Agent.delete(`/integritymanagement/${integrityId}`);

const getIntegrityManagementIntegritiesForWell = (externalWellboreId) =>
  Agent.get(`/integritymanagement/well/${externalWellboreId}`);

const addNewIntegrityWellData = (wellboreId, integrity) =>
  Agent.post(
    '/integritymanagement/well',
    integrityManagementMappers.integrities.create(wellboreId, integrity),
  );

const getIntegrityManagementWellIntegrityAttachments = (
  externalWellboreId,
  integrityId,
) =>
  Agent.get(
    `/integritymanagement/attachments/${externalWellboreId}/${integrityId}`,
  );

const getIntegrityManagementAvailableFiles = (externalWellboreId) =>
  Agent.get(`/integritymanagement/well/${externalWellboreId}/files`);

const uploadAndUpdateIntegrityManagementWellAttachments = (
  externalWellboreId,
  integrityId,
  files,
  meta,
) =>
  // technically attachments are fields so we need to see how to send them
  Agent.files.post(
    `/integritymanagement/attachments/upload/${externalWellboreId}/${integrityId}`,
    files,
    meta,
  );

const modifyIntegrityManagementWellAttachments = (
  externalWellboreId,
  integrityId,
  attachments,
) =>
  Agent.post(
    '/integritymanagement/attachments',
    integrityManagementMappers.attachments.to(
      externalWellboreId,
      integrityId,
      attachments,
    ),
  );

const integrityManagementService = {
  getIntegrityManagementIntegrities,
  getIntegrityManagementIntegrity,
  deleteIntegrityManagementIntegrity,
  addNewIntegrityManagementIntegrity,
  updateIntegrityManagementIntegrity,
  getIntegrityManagementIntegritiesForWell,
  addNewIntegrityWellData,
  getIntegrityManagementWellIntegrityAttachments,
  getIntegrityManagementAvailableFiles,
  uploadAndUpdateIntegrityManagementWellAttachments,
  modifyIntegrityManagementWellAttachments,
};

export default integrityManagementService;

import { useState, useEffect } from 'react';
import { DataSource } from '@int/geotoolkit/data/DataSource';
import { DataTable } from '@int/geotoolkit/data/DataTable';
import { LogCurveDataSource } from '@int/geotoolkit/welllog/data/LogCurveDataSource';
import jsonData from './test.json';
import { LogCurve } from '@int/geotoolkit/welllog/LogCurve';

class BindingFunction {
  constructor(dataTable) {
    this.dataTable = dataTable;
  }

  accept(node) {
    return node instanceof LogCurve;
  }

  bind(node, data) {
    if (!(data instanceof DataSource)) {
      return;
    }
    if (node instanceof LogCurve) {
      const curve = node;
      const id = curve.getName();
      const source = data.getCurveSource(id);
      if (source != null) {
        // console.log(`Binding curve '${id}' with data source`);
        curve.setData(source, true, true);
      } else {
        console.error(`No data source found for curve: ${id}`);
      }
    }
  }
}

async function createSimpleDataSource() {
  console.log('Creating Simple DataSource...');

  let maxLength = 0;
  Object.values(jsonData).forEach((dataArray) => {
    if (Array.isArray(dataArray) && dataArray.length > maxLength) {
      maxLength = dataArray.length;
    }
  });

  const depthIncrement = 0.008333333;
  const depths = Array.from(
    { length: maxLength },
    (_, index) => index * depthIncrement,
  );

  const columns = [{ name: 'depth', type: 'number' }];
  const columnsData = [depths];

  Object.keys(jsonData).forEach((key) => {
    columns.push({ name: key, type: 'number' });
    const curveData = jsonData[key];
    const paddedData = Array.from(
      { length: maxLength },
      (_, i) => curveData[i] ?? null,
    );
    columnsData.push(paddedData);
  });

  const dataTable = new DataTable({
    cols: columns,
    colsdata: columnsData,
  });

  const dataSource = new DataSource();
  dataSource.curveNames = Object.keys(jsonData).filter(
    (key) => key !== 'depth',
  ); // Expose curve names

  console.log('Data source created with curve names:', dataSource.curveNames);

  dataSource.getCurveSource = function (id) {
    const depths = dataTable.getColumnByName('depth');
    const values = dataTable.getColumnByName(id);

    if (!depths || !values) {
      console.error(`Failed to get data for curve ${id}`);
      return null;
    }

    return new LogCurveDataSource({
      datatable: dataTable,
      depths,
      values,
      name: id,
    });
  };

  // Define the connect method
  dataSource.connect = function (widget) {
    const binding = new BindingFunction(dataTable);
    const bindingRegistry = widget.getDataBinding();
    bindingRegistry.add(binding);

    widget.setData(dataSource);

    const depthColumn = dataTable.getColumnByName('depth');
    const rowCount = depthColumn.getLength();
    const minDepth = depthColumn.getValue(0);
    const maxDepth = depthColumn.getValue(rowCount - 1);
    widget.setDepthLimits(minDepth, maxDepth);
  };

  return dataSource;
}

export function useSimpleDataSource() {
  const [dataSource, setDataSource] = useState(null);

  useEffect(
    () => {
      if (!dataSource) {
        // Only initialize if dataSource is null
        const loadDataSource = async () => {
          const ds = await createSimpleDataSource();
          setDataSource(ds);
        };

        loadDataSource();
      }

      return () => {
        if (dataSource) {
          dataSource.dispose();
        }
      };
    },
    //eslint-disable-next-line
    [],
  ); // Remove dataSource from the dependency array

  return dataSource;
}

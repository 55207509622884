export const ACTIONS = {
  DELETE_SIMULATION: 'DELETE_SIMULATION',
  DELETE_SIMULATION_RESULTS: 'DELETE_SIMULATION_RESULTS',
  DELETE_SIMULATION_FLUIDS: 'DELETE_SIMULATION_FLUIDS',
  REQUEST_SIMULATION: 'REQUEST_SIMULATION',
  RECEIVE_SIMULATION: 'RECEIVE_SIMULATION',
  REQUEST_RUN_SIMULATION: 'REQUEST_RUN_SIMULATION',
  SUBMIT_CREATE_SIMULATION: 'SUBMIT_CREATE_SIMULATION',
  LOAD_SIMULATION: 'LOAD_SIMULATION',
  REQUEST_UPDATE_SIMULATION: 'REQUEST_UPDATE_SIMULATION',
  REQUEST_DELETE_SIMULATION: 'REQUEST_DELETE_SIMULATION',
  REQUEST_SIMULATION_RESULTS: 'REQUEST_SIMULATION_RESULTS',
  RECEIVE_SIMULATION_RESULTS: 'RECEIVE_SIMULATION_RESULTS',
  LOAD_SIMULATIONS: 'LOAD_SIMULATIONS',

  DELETE_SIMULATION_FLUID: 'DELETE_SIMULATION_FLUID',
  RECEIVE_SIMULATION_FLUID: 'RECEIVE_SIMULATION_FLUID',
  RECEIVE_SIMULATION_FLUIDS: 'RECEIVE_SIMULATION_FLUIDS',
  REQUEST_SIMULATION_FLUIDS: 'REQUEST_SIMULATION_FLUIDS',
  REQUEST_UPDATE_SIMULATION_FLUID: 'REQUEST_UPDATE_SIMULATION_FLUID',
  REQUEST_DELETE_SIMULATION_FLUID: 'REQUEST_DELETE_SIMULATION_FLUID',
  REQUEST_CREATE_SIMULATION_FLUID: 'REQUEST_CREATE_SIMULATION_FLUID',

  REQUEST_SIMULATION_WELLBORE_SECTIONS: 'REQUEST_SIMULATION_WELLBORE_SECTIONS',
  REQUEST_UPDATE_SIMULATION_WELLBORE_SECTION:
    'REQUEST_UPDATE_SIMULATION_WELLBORE_SECTION',

  REQUEST_SIMULATIONS_FOR_TASK: 'REQUEST_SIMULATIONS_FOR_TASK',
  RECEIVE_SIMULATIONS_FOR_TASK: 'RECEIVE_SIMULATIONS_FOR_TASK',
  REQUEST_DUPLICATE_SIMULATION: 'REQUEST_DUPLICATE_SIMULATION',
  REQUEST_CREATE_UPDATE_PLANNED_SIMULATION:
    'REQUEST_CREATE_UPDATED_PLANNED_SIMULATION',
  REQUEST_TOGGLE_SIMULATION_FAVORITE: 'REQUEST_TOGGLE_SIMULATION_FAVORITE',
  REQUEST_TOGGLE_SIMULATION_PLANNED: 'REQUEST_TOGGLE_SIMULATION_PLANNED',

  DOWNLOAD_SIMULATION_PDF_REPORT: 'DOWNLOAD_SIMULATION_PDF_REPORT',
};

export const SimulationFormFields = {
  NAME: 'name',
  SAFE_OPERATING_LOAD_SAFETY_FACTOR: 'workingLimitSafetyFactor', // TODO: Rename value when fixed in backend
  WEAKPOINT: 'weakpointLow', // TODO: Rename value when fixed in backend
  WEAKPOINT_SAFETY_FACTOR: 'weakpointSafetyFactor',
  CALCULATE_FROM_DEPTH: 'calculateFromDepth',
  CALCULATE_TO_DEPTH: 'calculateToDepth',
  COMMENT: 'comment',
  FLOW_PATH: 'flowPath',
  STUFFING_BOX_FRICTION: 'stuffingBoxFriction',
  ECCENTRICITY: 'eccentricity',
};

export const MODAL = {
  CREATE_SIMULATION_MODAL: 'CREATE_SIMULATION_MODAL',
  SIMULATION_EXPORT_MODAL_ID: 'SIMULATION_EXPORT_MODAL_ID',
};

export const SimulationDirection = {
  RUN_IN_HOLE: 0,
  PULL_OUT_OF_HOLE: 1,
};

export const SimulationResultSeries = {
  SURFACE_WEIGHT_RIH: 0,
  SURFACE_WEIGHT_POOH: 1,
  SURFACE_WEIGHT_SAFE_OPERATING_LOAD: 2,
  COMPRESSION_LIMIT: 3,
  WEAK_POINT_SAFETY_FACTOR: 4,
  WEAK_POINT: 5,
  MAX_SET_DOWN: 7,
  CABLE_HEAD_TENSION_RIH: 8,
  CABLE_HEAD_TENSION_POOH: 9,
  MAX_PICKUP_FORCE_POOH: 10,
  MIN_TRACTOR_PULL: 11,
  SW_JAR_ACTIVATION: 13,
  CHT_JAR_ACTIVATION: 15,
  STRETCH_RIH: 17,
  STRETCH_POOH: 18,
  COMPRESSION_DEPTH: 100,
};

export const SimulationState = {
  NOT_COMPLETED: 0,
  COMPLETED: 1,
  OUTDATED: 2,
  ERROR: 3,
  PLANNED: 4,
  UPDATED_PLANNED: 5,
};

export const FlowPath = {
  FLOW_UP: 0,
  FLOW_DOWN: 1,
};

export const SimulationChartsKeys = {
  OVERVIEW: 'OVERVIEW',
  SURFACE_WEIGHT_WL: 'SURFACE_WEIGHT_WL',
  SURFACE_WEIGHT_TL: 'SURFACE_WEIGHT_TL',
  WEAK_POINT: 'WEAK_POINT',
  MAX_PICKUP_FORCE_POOH: 'MAX_PICKUP_FORCE_POOH',
  MAX_SET_DOWN_FORCE: 'MAX_SET_DOWN_FORCE',
  MIN_TRACTOR_PULL: 'MIN_TRACTOR_PULL',
  CABLE_HEAD_TENSION_WL: 'CABLE_HEAD_TENSION_WL',
  CABLE_HEAD_TENSION_TL: 'CABLE_HEAD_TENSION_TL',
  STRETCH: 'STRETCH',
};

// Options 1 and 2 are removed as per the ticket 8480
export const SimulationChartsType = {
  OVERVIEW: 0,
  MAX_SET_DOWN_FORCE: 3,
  MAX_PICKUP_FORCE_POOH: 4,
  MIN_TRACTOR_PULL: 5,
  STRETCH: 6,
  WEAK_POINT: 7,
  SURFACE_WEIGHT_WL: 8,
  SURFACE_WEIGHT_TL: 9,
  CABLE_HEAD_TENSION_WL: 10,
  CABLE_HEAD_TENSION_TL: 11,
};

// this is temporary function since we are showing all the data from all data sets (before we only had one)
// createAlternativeSeriesId on simulation.utils.js
const createAlternativeActiveSeries = (
  simulationResultSeries,
  simulationResultDirection = SimulationDirection.PULL_OUT_OF_HOLE,
) => simulationResultSeries + 50 + simulationResultDirection;

// We now have the chart and series configuration both in the frontend and the backend.
// Later we should look into putting it in one common place.
export const SimulationCharts = [
  {
    key: SimulationChartsKeys.SURFACE_WEIGHT_WL,
    title: 'Surface Weight WL',
    unit: ['runInHole', 'surfaceTension'],
    compressionDepth: [
      {
        series: SimulationResultSeries.SURFACE_WEIGHT_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'surfaceTension',
      },
      {
        series: SimulationResultSeries.SURFACE_WEIGHT_POOH,
        direction: SimulationDirection.PULL_OUT_OF_HOLE,
        value: 'surfaceTension',
      },
    ],
    activeSeries: [
      SimulationResultSeries.SURFACE_WEIGHT_RIH,
      SimulationResultSeries.SURFACE_WEIGHT_POOH,
      SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      SimulationResultSeries.COMPRESSION_LIMIT,
      SimulationResultSeries.SW_JAR_ACTIVATION,
      // for second simulation set
      createAlternativeActiveSeries(SimulationResultSeries.SURFACE_WEIGHT_RIH),
      createAlternativeActiveSeries(SimulationResultSeries.SURFACE_WEIGHT_POOH),
      createAlternativeActiveSeries(
        SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      ),
      createAlternativeActiveSeries(SimulationResultSeries.COMPRESSION_LIMIT),
      createAlternativeActiveSeries(SimulationResultSeries.SW_JAR_ACTIVATION),
    ],
  },
  {
    key: SimulationChartsKeys.SURFACE_WEIGHT_TL,
    title: 'Surface Weight TL',
    unit: ['runInHole', 'surfaceTension'],
    compressionDepth: [
      {
        series: SimulationResultSeries.SURFACE_WEIGHT_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'surfaceTension',
      },
      {
        series: SimulationResultSeries.SURFACE_WEIGHT_POOH,
        direction: SimulationDirection.PULL_OUT_OF_HOLE,
        value: 'surfaceTension',
      },
    ],
    activeSeries: [
      SimulationResultSeries.SURFACE_WEIGHT_RIH,
      SimulationResultSeries.SURFACE_WEIGHT_POOH,
      SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      SimulationResultSeries.COMPRESSION_LIMIT,
      // for second simulation set
      createAlternativeActiveSeries(SimulationResultSeries.SURFACE_WEIGHT_RIH),
      createAlternativeActiveSeries(SimulationResultSeries.SURFACE_WEIGHT_POOH),
      createAlternativeActiveSeries(
        SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      ),
      createAlternativeActiveSeries(SimulationResultSeries.COMPRESSION_LIMIT),
    ],
  },
  {
    key: SimulationChartsKeys.WEAK_POINT,
    title: 'Weak Point',
    unit: ['runInHole', 'surfaceTension'],
    compressionDepth: [
      {
        series: SimulationResultSeries.SURFACE_WEIGHT_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'surfaceTension',
      },
      {
        series: SimulationResultSeries.SURFACE_WEIGHT_POOH,
        direction: SimulationDirection.PULL_OUT_OF_HOLE,
        value: 'surfaceTension',
      },
    ],
    activeSeries: [
      SimulationResultSeries.WEAK_POINT,
      SimulationResultSeries.WEAK_POINT_SAFETY_FACTOR,
      SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      SimulationResultSeries.SW_JAR_ACTIVATION,
      // for second simulation set
      createAlternativeActiveSeries(SimulationResultSeries.WEAK_POINT),
      createAlternativeActiveSeries(
        SimulationResultSeries.WEAK_POINT_SAFETY_FACTOR,
      ),
      createAlternativeActiveSeries(
        SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      ),
      createAlternativeActiveSeries(SimulationResultSeries.SW_JAR_ACTIVATION),
    ],
  },
  {
    title: 'Stretch',
    key: SimulationChartsKeys.STRETCH,
    unit: ['runInHole', 'stretch'],
    compressionDepth: [
      {
        series: SimulationResultSeries.STRETCH_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'stretch',
      },
      {
        series: SimulationResultSeries.STRETCH_POOH,
        direction: SimulationDirection.PULL_OUT_OF_HOLE,
        value: 'stretch',
      },
    ],
    activeSeries: [
      SimulationResultSeries.STRETCH_RIH,
      SimulationResultSeries.STRETCH_POOH,
      // for second simulation set
      createAlternativeActiveSeries(SimulationResultSeries.STRETCH_RIH),
      createAlternativeActiveSeries(SimulationResultSeries.STRETCH_POOH),
    ],
  },
  {
    title: 'Set Down Force',
    key: SimulationChartsKeys.MAX_SET_DOWN_FORCE,
    unit: ['runInHole', 'maxSetDown'],
    compressionDepth: [
      {
        series: SimulationResultSeries.MAX_SET_DOWN,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'maxSetDown',
      },
    ],
    activeSeries: [
      SimulationResultSeries.MAX_SET_DOWN,
      // for second simulation set
      createAlternativeActiveSeries(SimulationResultSeries.MAX_SET_DOWN),
    ],
  },
  {
    title: 'Pick-up Force',
    key: SimulationChartsKeys.MAX_PICKUP_FORCE_POOH,
    unit: ['pullOutOfHole', 'maxPickupWeight'],
    activeSeries: [
      SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      // for second simulation set
      createAlternativeActiveSeries(
        SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      ),
    ],
  },
  {
    title: 'Cablehead Tension WL',
    key: SimulationChartsKeys.CABLE_HEAD_TENSION_WL,
    unit: ['runInHole', 'cableHeadTension'],
    compressionDepth: [
      {
        series: SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'cableHeadTension',
      },
    ],
    activeSeries: [
      SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
      SimulationResultSeries.CABLE_HEAD_TENSION_POOH,
      SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      SimulationResultSeries.CHT_JAR_ACTIVATION,
      // for second simulation set
      createAlternativeActiveSeries(
        SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
      ),
      createAlternativeActiveSeries(
        SimulationResultSeries.CABLE_HEAD_TENSION_POOH,
      ),
      createAlternativeActiveSeries(
        SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      ),
      createAlternativeActiveSeries(SimulationResultSeries.CHT_JAR_ACTIVATION),
    ],
  },
  {
    title: 'Cablehead Tension TL',
    key: SimulationChartsKeys.CABLE_HEAD_TENSION_TL,
    unit: ['runInHole', 'cableHeadTension'],
    compressionDepth: [
      {
        series: SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'cableHeadTension',
      },
    ],
    activeSeries: [
      SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
      SimulationResultSeries.CABLE_HEAD_TENSION_POOH,
      SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      // for second simulation set
      createAlternativeActiveSeries(
        SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
      ),
      createAlternativeActiveSeries(
        SimulationResultSeries.CABLE_HEAD_TENSION_POOH,
      ),
      createAlternativeActiveSeries(
        SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      ),
    ],
  },
  {
    title: 'Tractor Pull',
    key: SimulationChartsKeys.MIN_TRACTOR_PULL,
    unit: ['runInHole', 'tractorForce'],
    activeSeries: [SimulationResultSeries.MIN_TRACTOR_PULL],
  },
  {
    title: 'All',
    key: SimulationChartsKeys.OVERVIEW,
    unit: null,
    compressionDepth: [
      {
        series: SimulationResultSeries.SURFACE_WEIGHT_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'surfaceTension',
      },
      {
        series: SimulationResultSeries.SURFACE_WEIGHT_POOH,
        direction: SimulationDirection.PULL_OUT_OF_HOLE,
        value: 'surfaceTension',
      },
      {
        series: SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'cableHeadTension',
      },
      {
        series: SimulationResultSeries.STRETCH_RIH,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'stretch',
      },
      {
        series: SimulationResultSeries.STRETCH_POOH,
        direction: SimulationDirection.PULL_OUT_OF_HOLE,
        value: 'stretch',
      },
      {
        series: SimulationResultSeries.MAX_SET_DOWN,
        direction: SimulationDirection.RUN_IN_HOLE,
        value: 'maxSetDown',
      },
    ],
    activeSeries: [
      SimulationResultSeries.SURFACE_WEIGHT_RIH,
      SimulationResultSeries.SURFACE_WEIGHT_POOH,
      SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      SimulationResultSeries.COMPRESSION_LIMIT,
      SimulationResultSeries.WEAK_POINT_SAFETY_FACTOR,
      SimulationResultSeries.WEAK_POINT,
      SimulationResultSeries.MAX_SET_DOWN,
      SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
      SimulationResultSeries.CABLE_HEAD_TENSION_POOH,
      SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      SimulationResultSeries.MIN_TRACTOR_PULL,
      SimulationResultSeries.SW_JAR_ACTIVATION,
      SimulationResultSeries.CHT_JAR_ACTIVATION,
      SimulationResultSeries.STRETCH_RIH,
      SimulationResultSeries.STRETCH_POOH,
      // for second simulation set
      createAlternativeActiveSeries(SimulationResultSeries.SURFACE_WEIGHT_RIH),
      createAlternativeActiveSeries(SimulationResultSeries.SURFACE_WEIGHT_POOH),
      createAlternativeActiveSeries(
        SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
      ),
      createAlternativeActiveSeries(SimulationResultSeries.COMPRESSION_LIMIT),
      createAlternativeActiveSeries(
        SimulationResultSeries.WEAK_POINT_SAFETY_FACTOR,
      ),
      createAlternativeActiveSeries(SimulationResultSeries.WEAK_POINT),
      createAlternativeActiveSeries(SimulationResultSeries.MAX_SET_DOWN),
      createAlternativeActiveSeries(
        SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
      ),
      createAlternativeActiveSeries(
        SimulationResultSeries.CABLE_HEAD_TENSION_POOH,
      ),
      createAlternativeActiveSeries(
        SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
      ),
      createAlternativeActiveSeries(SimulationResultSeries.MIN_TRACTOR_PULL),
      createAlternativeActiveSeries(SimulationResultSeries.SW_JAR_ACTIVATION),
      createAlternativeActiveSeries(SimulationResultSeries.CHT_JAR_ACTIVATION),
      createAlternativeActiveSeries(SimulationResultSeries.STRETCH_RIH),
      createAlternativeActiveSeries(SimulationResultSeries.STRETCH_POOH),
    ],
  },
];

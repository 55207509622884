import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const IntegrityFilterAttachmentDrawerHeader = () => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6" noWrap>
          Filter
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IntegrityFilterAttachmentDrawerHeader;

import React from 'react';
// import WellLogBasicComponent from './wellLogBasic/WellLogBasicComponent';
// import BigData from './wellLogBasic/WellLogBasicComponent';
// import BigDataTable from './wellLogBasic/WellLogBasicComponentTable';
import BigDataAndSchematic from './wellLogBasic/WellLogAndSchematicComponent';

function App() {
  return (
    <div className="App">
      {/* <WellLogBasicComponent /> */}
      <BigDataAndSchematic />
      {/* <BigDataTable /> */}
    </div>
  );
}

export default App;

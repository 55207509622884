import { compose } from 'redux';
import { Fab } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import { connect, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useEffect, useMemo, useCallback, useState } from 'react';

import { BasePage } from 'altus-ui-components';

import {
  requestProjectWellboreSections,
  requestCreateProjectWellboreSection,
  requestUpdateProjectWellboreSection,
  requestDeleteProjectWellboreSection,
  syncProjectWellboreSectionWithLatest,
  requestCreateProjectWellboreSectionNipple,
  requestUpdateProjectWellboreSectionNipple,
  requestDeleteProjectWellboreSectionNipple,
} from 'features/projects/wellbore/sections/projectWellboreSection.actions';

import {
  EMPTY_MAP,
  ProjectWellboreDetailStatus,
  PROJECT_STATUS,
} from 'app/app.constants';

import useCurrentProject from 'features/projects/hooks/useCurrentProject';

import { useHeader } from 'app/hooks/useHeader';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getProjectStatus } from 'features/projects/activities/activities.actions';
import WellboreSectionsTable from 'features/wells/sections/components/WellboreSectionsTable';
import { createProjectWellboreSectionsSelector } from 'features/projects/projects.selectors';
import { ACTIONS } from 'features/projects/wellbore/sections/projectWellboreSection.constants';
import useDefaultProjectWellboreSection from 'features/projects/hooks/useDefaultProjectWellboreSection';
import { getProjectWellboreDetailFromState } from 'features/projects/wellbore/details/projectWellboreDetails.selectors';
import { createAndRedirectProjectWellboreDetailDraftAndSync } from 'features/projects/wellbore/details/projectWellboreDetails.actions';

const ProjectWellboreSectionsContainer = ({
  classes,
  dataState,
  projectId,
  breadcrumb,
  activeWellboreDetailId,
  dispatchGetProjectStatus,
  dispatchCreateProjectWellboreSection,
  dispatchUpdateProjectWellboreSection,
  dispatchDeleteProjectWellboreSection,
  dispatchRequestProjectWellboreSections,
  dispatchCreateProjectWellboreSectionNipple,
  dispatchUpdateProjectWellboreSectionNipple,
  dispatchDeleteProjectWellboreSectionNipple,
  dispatchSyncProjectWellboreSectionWithLatest,
  dispatchSyncAndCreateProjectWellboreSectionWithLatest,
}) => {
  const [wellboreDetailId, setWellboreDetailId] = useState(null);
  useHeader({
    subTitle: breadcrumb,
  });

  const wellboreDetail = useSelector((state) =>
    getProjectWellboreDetailFromState(state, activeWellboreDetailId),
  );

  useEffect(() => {
    if (wellboreDetail) {
      setWellboreDetailId(wellboreDetail.get('wellboreDetailId'));
      dispatchRequestProjectWellboreSections(
        projectId,
        wellboreDetail.get('wellboreDetailId'),
      );
    }
  }, [projectId, wellboreDetail, dispatchRequestProjectWellboreSections]);

  const createWellboreSection = useCallback(
    (wellboreSection, formik, callback) =>
      dispatchCreateProjectWellboreSection(
        projectId,
        wellboreDetailId,
        wellboreSection,
        formik,
        callback,
      ),
    [projectId, wellboreDetailId, dispatchCreateProjectWellboreSection],
  );

  const updateWellboreSection = useCallback(
    (wellboreSection) => {
      const { wellboreSectionId } = wellboreSection;
      dispatchUpdateProjectWellboreSection(
        projectId,
        wellboreSectionId,
        wellboreDetailId,
        wellboreSection,
      );
    },
    [projectId, wellboreDetailId, dispatchUpdateProjectWellboreSection],
  );

  const deleteWellboreSection = useCallback(
    (wellboreSectionId) =>
      dispatchDeleteProjectWellboreSection(
        projectId,
        wellboreSectionId,
        wellboreDetailId,
      ),
    [projectId, wellboreDetailId, dispatchDeleteProjectWellboreSection],
  );

  const createWellboreSectionNipple = useCallback(
    (wellboreSectionNipple, formik, callback) => {
      const { wellboreSectionId } = wellboreSectionNipple;

      dispatchCreateProjectWellboreSectionNipple(
        projectId,
        wellboreSectionId,
        wellboreSectionNipple,
        formik,
        callback,
      );
    },
    [projectId, dispatchCreateProjectWellboreSectionNipple],
  );

  const updateWellboreSectionNipple = useCallback(
    (wellboreSectionNipple) => {
      const { wellboreSectionId, wellboreSectionNippleId } =
        wellboreSectionNipple;

      dispatchUpdateProjectWellboreSectionNipple(
        projectId,
        wellboreSectionId,
        wellboreDetailId,
        wellboreSectionNippleId,
        wellboreSectionNipple,
      );
    },
    [projectId, wellboreDetailId, dispatchUpdateProjectWellboreSectionNipple],
  );

  const deleteWellboreSectionNipple = useCallback(
    (wellboreSectionId, wellboreSectionNippleId) =>
      dispatchDeleteProjectWellboreSectionNipple(
        projectId,
        wellboreSectionId,
        wellboreDetailId,
        wellboreSectionNippleId,
      ),
    [projectId, wellboreDetailId, dispatchDeleteProjectWellboreSectionNipple],
  );

  const projectWellboreSectionsSelector = useMemo(
    () => createProjectWellboreSectionsSelector(projectId),
    [projectId],
  );

  const wellboreSections = useSelector(projectWellboreSectionsSelector);

  useEffect(() => {
    // Fetch status again if wellbore sections change, in case we need to ask/stop asking for confirmation before updating again.
    dispatchGetProjectStatus(projectId);
  }, [projectId, wellboreSections, dispatchGetProjectStatus]);

  const defaultWellboreSection = useDefaultProjectWellboreSection(
    projectId,
    wellboreDetailId,
  );

  const [latestProjectWellboreDetail, _] = useState(EMPTY_MAP);

  if (latestProjectWellboreDetail && !activeWellboreDetailId) {
    activeWellboreDetailId ??= latestProjectWellboreDetail.get(
      'projectWellboreDetailId',
    );
  }

  const currentProjectWellboreDetail = useSelector((state) =>
    activeWellboreDetailId
      ? getProjectWellboreDetailFromState(state, activeWellboreDetailId)
      : undefined,
  );

  const projectWellboreDetail =
    currentProjectWellboreDetail || latestProjectWellboreDetail;

  return (
    <BasePage title={breadcrumb} dataState={dataState}>
      <i>Basic Well Description for simulations</i>
      <br />
      <WellboreSectionsTable
        wellboreSections={wellboreSections}
        createWellboreSection={createWellboreSection}
        updateWellboreSection={updateWellboreSection}
        deleteWellboreSection={deleteWellboreSection}
        defaultWellboreSection={defaultWellboreSection}
        createWellboreSectionNipple={createWellboreSectionNipple}
        updateWellboreSectionNipple={updateWellboreSectionNipple}
        deleteWellboreSectionNipple={deleteWellboreSectionNipple}
        projectWellboreDetail={projectWellboreDetail}
      />
      <Fab
        onClick={() => {
          if (
            currentProjectWellboreDetail.get('status') ===
            ProjectWellboreDetailStatus.DRAFT
          )
            dispatchSyncProjectWellboreSectionWithLatest(
              projectId,
              wellboreDetailId,
            );
          else
            dispatchSyncAndCreateProjectWellboreSectionWithLatest(
              projectId,
              currentProjectWellboreDetail.get('projectWellboreDetailId'),
            );
        }}
        disabled={useCurrentProject().get('status') !== PROJECT_STATUS.PLAN}
        color="primary"
        title="Sync with latest"
        className={classes.fab}
      >
        <Refresh />
      </Fab>
    </BasePage>
  );
};

const styles = (theme) => ({
  fab: {
    zIndex: 2,
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
});

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        ACTIONS.REQUEST_PROJECT_WELLBORE_SECTIONS,
        ACTIONS.REQUEST_CREATE_PROJECT_WELLBORE_SECTION,
        ACTIONS.REQUEST_UPDATE_PROJECT_WELLBORE_SECTION,
        ACTIONS.REQUEST_DELETE_PROJECT_WELLBORE_SECTION,
        ACTIONS.REQUEST_CREATE_PROJECT_WELLBORE_SECTION_NIPPLE,
        ACTIONS.REQUEST_UPDATE_PROJECT_WELLBORE_SECTION_NIPPLE,
        ACTIONS.REQUEST_DELETE_PROJECT_WELLBORE_SECTION_NIPPLE,
      ),
    }),
    {
      dispatchGetProjectStatus: getProjectStatus,
      dispatchRequestProjectWellboreSections: requestProjectWellboreSections,
      dispatchCreateProjectWellboreSection: requestCreateProjectWellboreSection,
      dispatchUpdateProjectWellboreSection: requestUpdateProjectWellboreSection,
      dispatchDeleteProjectWellboreSection: requestDeleteProjectWellboreSection,
      dispatchCreateProjectWellboreSectionNipple:
        requestCreateProjectWellboreSectionNipple,
      dispatchUpdateProjectWellboreSectionNipple:
        requestUpdateProjectWellboreSectionNipple,
      dispatchDeleteProjectWellboreSectionNipple:
        requestDeleteProjectWellboreSectionNipple,
      dispatchSyncAndCreateProjectWellboreSectionWithLatest:
        createAndRedirectProjectWellboreDetailDraftAndSync,
      dispatchSyncProjectWellboreSectionWithLatest:
        syncProjectWellboreSectionWithLatest,
    },
  ),
  withStyles(styles),
)(ProjectWellboreSectionsContainer);

const integrityIntegritiesFromDTO = ({ ...integrities }) => {
  return {
    ...integrities,
  };
};

const integrityIntegritiesToDTO = ({ ...integrities }) => {
  return {
    ...integrities,
  };
};

const integrityWellDataCreateDTO = (wellboreId, { ...integrities }) => {
  return {
    wellId: wellboreId,
    integrityId: integrities.integrityId,
    integrityDetailType: integrities.integrityDetailType,
    value: integrities.value,
  };
};

const integrityAttachmentsFromDTO = ({ ...attachments }) => {
  return {
    ...attachments,
  };
};

const integrityAttachmentsToDTO = (
  externalWellboreId,
  integrityId,
  attachments,
) => {
  return {
    wellId: externalWellboreId,
    integrityId: integrityId,
    attachments: attachments?.map(filesToIntegrityAttachmentDTO),
  };
};

// mapping for GetWellAttachmentDto
const filesToIntegrityAttachmentDTO = (attachment) => ({
  externalFileId: attachment.externalId,
  reportedDate: attachment.created,
  fileName: attachment.fullName,
  fileType: attachment.extension,
  fileLink: attachment.editDocumenturl,
  fileSource: attachment.fileSource,
});

const integrityAvailableFilesFromDTO = ({ ...availableFiles }) => {
  return {
    ...availableFiles,
  };
};

const integrityManagementMappers = {
  integrities: {
    from: integrityIntegritiesFromDTO,
    to: integrityIntegritiesToDTO,
    create: integrityWellDataCreateDTO,
  },
  attachments: {
    from: integrityAttachmentsFromDTO,
    to: integrityAttachmentsToDTO,
    mapAttachment: filesToIntegrityAttachmentDTO,
  },
  availableFiles: {
    from: integrityAvailableFilesFromDTO,
  },
};

export default integrityManagementMappers;

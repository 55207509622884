import { compose } from 'redux';
import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PlusIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import DoneIcon from '@material-ui/icons/Done';
import { green } from '@material-ui/core/colors';
import CompareIcon from '@material-ui/icons/Compare';
import FilterList from '@material-ui/icons/FilterList';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Button, Box } from '@material-ui/core';

import { useToggle } from 'altus-hooks';
import { BasePage } from 'altus-ui-components';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import {
  getIntegrityManagementAvailableFilesState,
  getIntegrityManagementIntegritiesFromStateByRiskPrinciple,
  getIntegrityManagementWellIntegrityAttachmentsFromState,
} from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import {
  INTEGIRTY_ATTACHMENT_SOURCE_TYPE_ENUM,
  INTEGRITY_MANAGEMENT_ACTIONS,
  RISK_PRINCIPLE_TYPES,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import {
  loadIntegrityManagementAvailableFiles,
  loadIntegrityManagementIntegritiesForWell,
  loadIntegrityManagementWellIntegrityAttachments,
  updateIntegrityManagementWellAttachments,
  uploadAndUpdateIntegrityManagementWellAttachments,
} from 'features/settings/integrityManagement/helpers/integrityManagement.actions';

import useWellbore from 'features/wells/hooks/useWellbore';
import { useState } from 'react';
import BackButton from 'app/components/BackButton';
import IntegrityAttachmentsTable from './attachments/IntegrityAttachmentsTable';
import { EMPTY_LIST } from 'app/app.constants';
import UploadIntegrityAttachmentDrawer from './attachments/upload/UploadIntegrityAttachmentDrawer';
import IntegrityAttachmentsPreview from './attachments/IntegrityAttachmentPreview';
import IntegrityFilterAttachmentDrawer from './attachments/filter/IntegrityFilterAttachmentDrawer';

export const DEFAULT_ATTACHMENT_FILTERS = {
  fileTypes: {},
  years: {},
};

const IntegrityAttachmentsContainer = ({
  classes,
  dataState,
  wellboreId,
  integrityId,
  attachments,
  availableFiles,
  dispatchLoadWellIntegrityAttachments,
  dispatchLoadIntegrityManagementForWell,
  dispatchUpdateWellIntegrityAttachments,
  dispatchLoadWellIntegrityAvailableFiles,
  dispatchUploadAndUpdateWellIntegrityAttachments,
}) => {
  const history = useHistory();

  const [isOpen, toggle] = useToggle();
  const [isFilterOpen, toggleFilterDrawer] = useToggle();
  const { wellbore } = useWellbore(wellboreId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [externalWellboreId, setExternalWellboreId] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const [wellboreFiles, setWellboreFiles] = useState(EMPTY_LIST);
  const [projectFiles, setProjectFiles] = useState(EMPTY_LIST);

  useEffect(
    () => {
      if (wellbore.get('externalWellboreExternalId')) {
        dispatchLoadIntegrityManagementForWell(
          wellbore.get('externalWellboreExternalId'),
        );
        setExternalWellboreId(wellbore.get('externalWellboreExternalId'));

        if (integrityId) {
          dispatchLoadWellIntegrityAttachments(
            wellbore.get('externalWellboreExternalId'),
            integrityId,
          );
          dispatchLoadWellIntegrityAvailableFiles(
            wellbore.get('externalWellboreExternalId'),
          );
        }
      }
    }, // eslint-disable-next-line
    [wellbore, integrityId],
  );

  useEffect(() => {
    if (availableFiles && availableFiles !== EMPTY_LIST) {
      if (attachments && attachments !== EMPTY_LIST) {
        const attachmentsExternalIds = attachments.map(
          (item) => item.externalFileId,
        );

        const wellboreList = availableFiles
          .filter((item) => item.wellboreId !== null)
          .map((item) => ({
            ...item,
            isChecked: attachmentsExternalIds.includes(item.externalId),
            fileSource: INTEGIRTY_ATTACHMENT_SOURCE_TYPE_ENUM.WELL,
          }));

        setWellboreFiles(wellboreList);

        const projectList = availableFiles
          .filter((item) => item.projectId && item.projectId !== 0) // Filter valid items
          .map((item) => ({
            ...item,
            isChecked: attachmentsExternalIds.includes(item.externalId),
            fileSource: INTEGIRTY_ATTACHMENT_SOURCE_TYPE_ENUM.PROJECT,
          }))
          .groupBy((item) => item.projectId); // Group by `projectId`

        setProjectFiles(projectList);
      }
      // this is for the case we have an empty well integrity rule - with no attachments at all
      else {
        const wellboreList = availableFiles
          .filter((item) => item.wellboreId !== null)
          .map((item) => ({
            ...item,
            isChecked: false,
            fileSource: INTEGIRTY_ATTACHMENT_SOURCE_TYPE_ENUM.WELL,
          }));

        setWellboreFiles(wellboreList);

        const projectList = availableFiles
          .filter((item) => item.projectId && item.projectId !== 0) // Filter items with projectId - those are from project
          .map((item) => ({
            ...item,
            isChecked: false,
            fileSource: INTEGIRTY_ATTACHMENT_SOURCE_TYPE_ENUM.PROJECT,
          }))
          .groupBy((item) => item.projectId); // Group by `projectId`

        setProjectFiles(projectList);
      }
    }
  }, [availableFiles, attachments]);

  const handleUpdateAttachments = (newAttachments, newFiles) => {
    // if there are any files call update of the files
    if (newFiles.length > 0) {
      dispatchUploadAndUpdateWellIntegrityAttachments(
        externalWellboreId,
        integrityId,
        newAttachments,
        newFiles,
        toggle,
      );
    } else {
      dispatchUpdateWellIntegrityAttachments(
        externalWellboreId,
        integrityId,
        newAttachments,
        toggle,
      );
    }
  };

  // dispatch will close the modal, enable editing on repopen
  useEffect(() => {
    if (!isOpen) {
      setIsSubmitting(false);
    }
  }, [isOpen]);

  // attachment filters
  const [filteredAttachments, setFilteredAttachments] = useState(EMPTY_LIST);
  const [filters, setFilters] = useState(DEFAULT_ATTACHMENT_FILTERS);

  useEffect(
    () => {
      const updateFilteredAttachments = () => {
        const { fileTypes, years } = filters;

        const newFilteredAttachments = attachments.filter((attachment) => {
          const fileType = attachment.fileType;
          const reportedDate = attachment.reportedDate;

          const date = new Date(reportedDate);
          const year = date.getFullYear();
          const month = date.toLocaleString('default', { month: 'long' });

          // Filtering logic:
          // 1. Include the attachment if its fileType is selected.
          // 2. Check if the month is explicitly selected in the year's `months`.

          const isFileTypeSelected = fileTypes[fileType];
          const isMonthSelected = years[year]?.months[month];

          // Include only if all conditions are true
          return isFileTypeSelected && isMonthSelected;
        });

        setFilteredAttachments(newFilteredAttachments);
      };

      if (filters !== DEFAULT_ATTACHMENT_FILTERS) {
        updateFilteredAttachments();
      }
    },
    // eslint-disable-next-line
    [filters],
  );

  useEffect(() => {
    const initializeFilters = () => {
      const fileTypes = {};
      const years = {};

      // Extract unique fileTypes and group dates
      attachments.forEach((attachment) => {
        const { fileType, reportedDate } = attachment;

        // Initialize fileType filters
        if (!fileTypes[fileType]) {
          fileTypes[fileType] = true; // Default to true since all filters should be on by default
        }

        // Group reportedDate by year and month
        const date = new Date(reportedDate);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });

        if (!years[year]) {
          years[year] = {
            isSelected: true, // Default to true since all filters should be on by default
            months: {},
          };
        }

        if (!years[year].months[month]) {
          years[year].months[month] = true; // Default to true since all filters should be on by default
        }
      });

      setFilters({ fileTypes, years });
    };

    initializeFilters();
    setFilteredAttachments(attachments);
  }, [attachments]);

  return (
    <BasePage dataState={dataState}>
      <Grid item container spacing={2} className={classes.tableContainer}>
        {externalWellboreId && (
          <Grid item spacing={2} xs={12} className={classes.container}>
            <Grid item xs={6} spacing={2}>
              <Grid item xs={12} container direction="row">
                <Grid item xs={2}>
                  <BackButton onClick={() => history.goBack()} />
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6" align="left">
                    Production String Footage Run
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    title="Upload document"
                    color="primary"
                    variant="contained"
                    onClick={toggle}
                  >
                    <PlusIcon fontSize="small" />
                    Upload document
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button title="Compare" color="primary" variant="contained">
                    <CompareIcon fontSize="small" />
                    Compare
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.statusText}>
                <Box display="flex" justifyContent="flex-end">
                  {attachments?.size > 0 && (
                    <Typography>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <DoneIcon className={classes.successIcon} />
                        {'Rule compliant. Document(s) uploaded'}
                      </span>
                    </Typography>
                  )}
                  {attachments?.size === 0 && (
                    <Typography align="right" nowrap>
                      <InfoIcon color="primary" fontSize="small" />
                      {'Rule not compliant. Document(s) missing'}
                    </Typography>
                  )}
                </Box>
              </Grid>
              {attachments && attachments !== EMPTY_LIST && (
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <Typography align="left" nowrap>
                      {'Supporting document(s)'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        title="Compare"
                        color="secondary"
                        variant="contained"
                        onClick={toggleFilterDrawer}
                        className={classes.transparentButton}
                      >
                        <FilterList fontSize="small" />
                        Filter
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                {attachments && attachments !== EMPTY_LIST && (
                  <IntegrityAttachmentsTable
                    attachments={filteredAttachments}
                    setSelectedAttachment={setSelectedAttachment}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.preview}>
              {!isNil(selectedAttachment) && <IntegrityAttachmentsPreview />}
            </Grid>
          </Grid>
        )}
      </Grid>
      <UploadIntegrityAttachmentDrawer
        isOpen={isOpen}
        toggleDrawer={toggle}
        attachments={attachments}
        wellboreFiles={wellboreFiles}
        projectFiles={projectFiles}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        handleUpdateAttachments={handleUpdateAttachments}
      />
      <IntegrityFilterAttachmentDrawer
        isOpen={isFilterOpen}
        toggleDrawer={toggleFilterDrawer}
        filters={filters}
        setFilters={setFilters}
      />
    </BasePage>
  );
};

const styles = (theme) => ({
  tableContainer: {
    padding: theme.spacing(2),
  },
  preview: {
    // background: theme.palette.tabs.background.light,
    padding: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  successIcon: {
    color: green[600],
    marginRight: theme.spacing(1),
  },
  statusText: {
    padding: theme.spacing(2),
  },
  transparentButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: 'inherit',
  },
});

export default compose(
  connect(
    (state) => ({
      filteredIntegrities:
        getIntegrityManagementIntegritiesFromStateByRiskPrinciple(
          state,
          RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE,
        ),
      dataState: getSummarizedDataStateFromState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL,
      ),
      attachments: getIntegrityManagementWellIntegrityAttachmentsFromState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_WELL_INTEGRITY_ATTACHMENTS,
      ),
      availableFiles: getIntegrityManagementAvailableFilesState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_AVAILABLE_FILES,
      ),
    }),
    {
      dispatchLoadIntegrityManagementForWell:
        loadIntegrityManagementIntegritiesForWell,
      dispatchLoadWellIntegrityAttachments:
        loadIntegrityManagementWellIntegrityAttachments,
      dispatchLoadWellIntegrityAvailableFiles:
        loadIntegrityManagementAvailableFiles,
      dispatchUpdateWellIntegrityAttachments:
        updateIntegrityManagementWellAttachments,
      dispatchUploadAndUpdateWellIntegrityAttachments:
        uploadAndUpdateIntegrityManagementWellAttachments,
    },
  ),
  withStyles(styles),
)(IntegrityAttachmentsContainer);
